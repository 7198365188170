import { useState, useEffect } from 'react';
import { usePaystackPayment } from 'react-paystack';
import { useSWRConfig } from 'swr';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Page from '../../components/Page';
import { BankTransferCard, CheckoutCard } from '../../components/cards';
import ServiceForm from '../../components/forms/ServiceForm';
import APIService from '../../service';
import getPercentage from '../../utils/getPercentage';
import { changeLayout, setLayoutState, setNewService, setOpenForm } from '../../store/reducer/serviceLayout';
import CustomModal from '../../components/modal/CustomModal';
import LoadingBackdrop from '../../components/loading/Backdrop';

const StyledGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 16,
  height: '100%',
  marginTop: 10,
  paddingBottom: theme.spacing(5),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(6),
    marginLeft: theme.spacing(6),
  },
}));

const signupSchema = Yup.object().shape({
  businessName: Yup.string().required('Business Name is required'),
  businessDesc: Yup.string(),
  businessType: Yup.string().required('Business Type is required'),
  rcNumber: Yup.string(),
  domain: Yup.string(),
  role: Yup.string().required('Business Type is required'),
  country: Yup.string().required('Country is required'),
  address: Yup.string(),
});

function Checkout(props) {
  const { profile } = props;
  const VAT = parseInt(process.env.REACT_APP_VAT_PERCENT);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const { state } = useSelector((state) => state.serviceLayout);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [paymentRecieved, setPaymentReceived] = useState(false);
  const [onlinePaymentResponse, setOnlinePaymentResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [paymentProof, setPaymentProof] = useState('');
  const [agreementError, setAgreementError] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [errorStatus, setErrorStatus] = useState({
    field: '',
    message: '',
  });

  const amount = parseInt(state?.price || state?.amount || state?.subPackages?.price);
  const percentAmount = getPercentage(VAT, amount);
  const subTotal = amount - percentAmount;
  const total = amount;

  const [initialValues, setInitialValues] = useState({
    businessName: '',
    businessDesc: '',
    businessType: 'Retailers',
    rcNumber: '',
    domain: '',
    role: 'Executive (CFO)',
    country: 'Nigeria',
    address: '',
    paymentType: 'debitcard',
    status: 'inactive',
  });

  const config = {
    reference: `INVOICE_${new Date().getTime().toString()}`,
    email: profile?.emailAddress,
    firstname: profile?.username,
    phone: profile?.phoneNumber?.replace('+234', '0'),
    amount: total * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    channels: ['card'],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: signupSchema,
    onSubmit: async () => {
      setAgreementError(false);
      if (!agreement) {
        setAgreementError(true);
        return toast.error('Please accept the terms and condition to continue');
      }
      if (initialValues.paymentType === 'bank-transfer') {
        handleStoreData('transfer', null);
      } else {
        if (!paymentRecieved) {
          initializePayment(onSuccess, onClose);
        } else {
          handleStoreData('online', onlinePaymentResponse);
        }
      }
    },
  });

  const initializePayment = usePaystackPayment(config);

  const handleCheckout = async () => {
    formik.submitForm();
  };

  const onSuccess = (reference) => {
    if (reference?.status === 'success') {
      setPaymentReceived(true);
      setOnlinePaymentResponse(reference);
      handleStoreData('online', reference);
    }
  };
  const onClose = () => { };

  const handleTransferDone = () => {
    if (!paymentProof) {
      return setErrorStatus({
        field: 'paymentProof',
        message: 'Please upload your proof of payment',
      });
    }
    setOpenTransferModal(false);
    navigate('/dashboard/invoices', { replace: true });
    dispatch(changeLayout('all'));
  };

  const handleUploadedFile = async (data, id) => {
    setErrorStatus({
      field: '',
      message: '',
    });
    setPaymentProof(data?.secure_url || data?.url);
    //update invoice
    await APIService.update('/invoice/update', invoice?.id, {
      paymentProof: data?.secure_url || data?.url,
    });
  };

  const handleStoreData = async (type, reference) => {
    const payload = {
      package: state,
      business: { ...formik.values, paymentType: initialValues.paymentType },
      amount: total,
      reference: reference?.reference,
    };

    setLoading(true);

    if (!profile?.hasBoarded) {
      await APIService.update('/auth', 'update', { hasBoarded: true });
      mutate('/auth/profile');
    }

    const response = APIService.post('/service/create', payload);

    toast.promise(
      response,
      {
        loading: 'loading',
        success: (res) => {
          setLoading(false);
          dispatch(setNewService(true));
          dispatch(setOpenForm(false));
          dispatch(setLayoutState(res.data.service));
          setInvoice(res.data?.invoice);
          mutate('/service/single');
          if (type === 'online') {
            //reset package selection and layout
            //route to service
            dispatch(changeLayout('all'));
          } else {
            setOpenTransferModal(true);
          }
          return type === 'online'
            ? 'Your payment has been received'
            : `We have created your Invoice ID (#${res.data?.invoice?.invoiceId}), Please make your transfer to the following account details`;
        },
        error: (err) => {
          setLoading(false);
          return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
        },
      },
      {
        duration: 10000,
      }
    );
  };

  return (
    <Page title="Checkout">
      {loading && <LoadingBackdrop open={loading} setOpen={setLoading} color='success' />}
      <CustomModal
        open={openTransferModal}
        setOpen={handleTransferDone}
        title="Make Bank Transfer"
        modalSize="xs"
        background
      >
        <BankTransferCard
          amount={total || invoice?.amount}
          handleDone={handleTransferDone}
          paymentProof={paymentProof}
          toast={toast}
          errorStatus={errorStatus}
          handleUploadedFile={handleUploadedFile}
        />
      </CustomModal>
      <Container maxWidth="xl">
        <StyledGrid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" gutterBottom>
              Business Information
            </Typography>
            <ServiceForm
              formik={formik}
              selectedPackage={state}
              agreement={agreement}
              setAgreement={setAgreement}
              agreementError={agreementError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledBox>
              <Typography variant="h5" gutterBottom>
                Order Summary
              </Typography>
              <CheckoutCard
                loading={loading}
                selectedPackage={state}
                handleCheckout={handleCheckout}
                setInitialValues={setInitialValues}
                initialValues={initialValues}
                amount={amount}
                total={total}
                subTotal={subTotal}
                percentAmount={percentAmount}
                vat={VAT}
              />
            </StyledBox>
          </Grid>
        </StyledGrid>
      </Container>
      <Toaster />
    </Page>
  );
}

export default Checkout;
