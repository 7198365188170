import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import Spacer from '../spacer';
import Iconify from '../Iconify';
import formatCurrency from '../../utils/formatCurrency';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  width: '100%',
  borderRadius: 10,
}));
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 0,
}));

const Item = ({ keyName, value, bold, fontSize = 16 }) => (
  <ListItem divider secondaryAction={<Typography sx={{ fontSize, fontWeight: 'bolder' }}>{value}</Typography>}>
    <ListItemText
      primary={
        <Typography color="text.secondary" sx={{ fontSize }}>
          {keyName}
        </Typography>
      }
    />
  </ListItem>
);

function WithdrawCard(props) {
  const { bank } = props;
  return (
    <StyledCard elevation={0}>
      <StyledCardContent>
        <Box sx={{ textAlign: 'center', my: 5 }}>
          <CardMedia
            src="/static/images/awaiting.svg"
            alt="..."
            component="img"
            height="250"
            sx={{
              objectFit: 'contain',
            }}
          />
          <Typography variant="h4">Please await while we process your withdrawal</Typography>
          <Typography variant="subtitle2" fontWeight="lighter" color="text.secondary">
            You will receive fund to your bank account in a short while.
          </Typography>
        </Box>
        <List>
          <Item keyName="Bank Name" value={bank?.bankName} bold />
          <Item keyName="Account Number" value={bank?.accountNumber} bold />
          <Item keyName="Account Name" value={bank?.accountName} bold />
        </List>
      </StyledCardContent>
    </StyledCard>
  );
}

export default WithdrawCard;
