import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';
import LoadingButton from '@mui/lab/LoadingButton';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Iconify from '../Iconify';
import formatCurrency from '../../utils/formatCurrency';
import getPercentage from '../../utils/getPercentage';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  borderRadius: 10,
  transition: 'all 200ms ease-in',
  '&:hover': {
    transform: 'scale(1.01)',
  },
}));
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 0,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: 'text.primary',
}));

const Item = ({ keyName, value, bold, fontSize = 14 }) => (
  <ListItem
    divider
    secondaryAction={
      <StyledTypography sx={{ fontSize, fontWeight: bold ? 'bolder' : 'normal' }}>{value}</StyledTypography>
    }
  >
    <ListItemText
      primary={<StyledTypography sx={{ fontSize, fontWeight: bold ? 'bolder' : 'normal' }}>{keyName}</StyledTypography>}
    />
  </ListItem>
);

PreviewInvoiceCard.propTypes = {
  data: PropType.object,
  loading: PropType.bool,
  handleCheckout: PropType.func,
  paymentType: PropType.string,
  setPaymentType: PropType.func,
};

export default function PreviewInvoiceCard(props) {
  const { data, loading, handleCheckout, paymentType, setPaymentType } = props;

  const packageDetail =
    data?.service?.package?.category === 'website'
      ? {
          ...data?.subPackage,
          category: data?.service?.package?.category,
          interval: data?.service?.package.interval,
          title: data?.service?.package.name,
          amount: data?.amount,
        }
      : { ...data?.service?.package, amount: data?.amount };

  const amount = parseInt(packageDetail?.amount);
  const percentAmount = getPercentage(parseInt(process.env.REACT_APP_VAT_PERCENT), packageDetail?.price);
  const subTotal = amount - percentAmount;
  const total = amount;

  const handleChangePayment = (value) => {
    setPaymentType(value);
  };

  return (
    <StyledCard>
      <StyledCardContent>
        <Box sx={{ flexGrow: 1 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ p: 2, bgcolor: 'rgb(255 255 255 / 10%)', mb: 3 }}
          >
            <div>
              <StyledTypography variant="caption" textTransform="capitalize">
                {packageDetail?.category}
              </StyledTypography>
              <StyledTypography variant="h6" textTransform="capitalize">
                {packageDetail?.name} /{' '}
                <span style={{ fontSize: 13, fontWeight: 'lighter' }}>{packageDetail?.interval}</span>
              </StyledTypography>
            </div>
            <StyledTypography variant="h4">{formatCurrency(packageDetail?.price)}</StyledTypography>
          </Stack>
          {data?.status === 'unpaid' ? (
            <FormControl sx={{ px: 2 }} fullWidth>
              <FormLabel id="paymentType" sx={{ fontSize: 14, mb: 1 }}>
                Select Your Payment Method
              </FormLabel>
              <ButtonGroup aria-labelledby="paymentType" variant="outlined" fullWidth>
                <Button
                  startIcon={<Iconify icon="bi:credit-card" />}
                  onClick={() => handleChangePayment('debitcard')}
                  color="secondary"
                  sx={{
                    borderWidth: paymentType === 'debitcard' ? 2 : 1,
                    borderColor: paymentType === 'debitcard' ? 'secondary.main' : 'secondary.light',
                    color: paymentType === 'debitcard' ? 'secondary.main' : 'secondary.light',
                  }}
                >
                  Debit Card
                </Button>
                <Button
                  startIcon={<Iconify icon="bx:transfer-alt" />}
                  onClick={() => handleChangePayment('bank-transfer')}
                  color="secondary"
                  sx={{
                    borderWidth: paymentType === 'bank-transfer' ? 2 : 1,
                    borderColor: paymentType === 'bank-transfer' ? 'secondary.main' : 'secondary.light',
                    color: paymentType === 'bank-transfer' ? 'secondary.main' : 'secondary.light',
                  }}
                >
                  Bank Transfer
                </Button>
              </ButtonGroup>
            </FormControl>
          ) : null}
          <List sx={{ px: 0, mt: 2 }}>
            <Item keyName="Subtotal" value={formatCurrency(subTotal)} />
            <Item keyName={`VAT (${process.env.REACT_APP_VAT_PERCENT}%)`} value={formatCurrency(percentAmount)} />
            <Item keyName="Total" value={formatCurrency(total)} fontSize={16} bold />
          </List>
        </Box>
        {data?.status === 'unpaid' ? (
          <Box sx={{ mx: 2, mt: 3 }}>
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              loading={loading}
              onClick={handleCheckout}
            >
              Checkout {formatCurrency(total)}
            </LoadingButton>
          </Box>
        ) : null}
      </StyledCardContent>
    </StyledCard>
  );
}
