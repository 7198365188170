import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
// sections
import { ForgottenPasswordForm } from '../../components/forms';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  // height: '100%',
  borderRadius: 16,
  maxWidth: 400,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const ColoredTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? theme.palette.primary.darker : theme.palette.primary.lighter,
}));

// ----------------------------------------------------------------------

export default function ForgottenPassword(props) {
  const { mutate } = props;
  const { state } = useLocation();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ContentStyle>
        <ColoredTypography variant="h3">Forgotten Password?</ColoredTypography>

        <Typography sx={{ color: 'text.secondary', mb: 8 }}>Enter your email to reset your password.</Typography>
        <ForgottenPasswordForm mutate={mutate} state={state} />
      </ContentStyle>

      <Stack direction="column" alignItems="center" justifyContent="center" spacing={2} sx={{ my: 2 }}>
        <Box>
          I know my password?
          <Link
            component={RouterLink}
            variant="subtitle2"
            color="primary"
            to="/account/login"
            underline="hover"
            sx={{ marginLeft: 1 }}
          >
            Login
          </Link>
        </Box>
      </Stack>
    </Box>
  );
}
