// ----------------------------------------------------------------------

export default function Dialog() {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: '100%',
        },
      },
    },
  };
}
