import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Carousel from 'react-multi-carousel';
import Typography from '@mui/material/Typography';


const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  }


function Clients(props) {
    const {items} = props;
  return (
    <Box paddingY={6}>
        <Typography
          variant={'h4'}
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{ fontWeight: 700, color: 'common.white' }}
          
        >
          Our most valuable partners
        </Typography>
        <Carousel
          autoPlay
          showDots={false}
          arrows={false}
          ssr={false}
          responsive={responsive}
        >
        {items?.map((item, index) => (
            <Box key={index} component='img' src={item} alt='...' sx={{width: '100%', height: 120, objectFit: 'contain' }} />
        ))}
    </Carousel>
    </Box>
  )
}

export default Clients;

Clients.propTypes = {
    items: PropTypes.array
}