import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { alpha, styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Logo from '../../../../components/Logo';
import Iconify from '../../../../components/Iconify';
import { useEffect } from 'react';


const StyledLink = styled(Link)(({theme}) => ({
  transition: 'color 400ms ease',
  '&:hover': {
    color: alpha(theme.palette.common.white, 0.2),
    transform: 'scale(1.05)',
  },
}));

const SidebarNav = ({ pages, onClose, pathname }) => {
  const theme = useTheme();

  return (
    <Box paddingBottom={2}>
      <Box
        justifyContent={'flex-end'}
        onClick={onClose}
        display={{ xs: 'flex', md: 'none' }}
        marginBottom={4}
        paddingX={2}
      >
        <Logo dark={theme.palette.mode === 'dark'} size={30} title='Webonthego' titleColor={theme.palette.mode === 'dark' ? 'white': 'primary'} />
        <CloseIcon fontSize="small" />
      </Box>
      <Box paddingX={2}>
      {pages.map((page, index) => page.button ? 
        <Box marginBottom={1 / 2} key={index}>
          <Button
            component={RouterLink}
            to={page.href}
            variant='contained'
            endIcon={<Iconify icon='eva:arrow-ios-forward-fill' />}
            fullWidth
          >
            {page.name}
          </Button>
        </Box> : <Box marginBottom={4}>
          <StyledLink component={RouterLink} to={page.href} onClick={onClose} color={pathname === page.href ? 'primary.light' : "text.primary"}>
              {page.name}
            </StyledLink>
            </Box> )}
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  pathname: PropTypes.string
};

export default SidebarNav;
