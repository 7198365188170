import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from "../../../../components/container";

function MiniHero(props) {
    const {title, desc, image} = props;
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        minHeight: 250,
        overflow: 'hidden',
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.53)),
        url('${image}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        // backgroundAttachment: 'fixed'
      }}
    >
        <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
            <Box marginTop={10} marginX={{xs: 2, md: 0}} data-aos={'fade-up'}>
                <Typography variant="h2" color="common.white" sx={{ fontWeight: 700 }}>
                    {title}
                </Typography>
                <Typography variant="h6" component="p" color="rgb(255 255 255 / 78%)">
                    {desc}
                </Typography>
            </Box>
        </Container>
    </Box>
  )
}

export default MiniHero;

MiniHero.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    image: PropTypes.string
};