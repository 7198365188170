import { useState, useEffect } from "react";
import { alpha, styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TypeAnimation from 'react-type-animation';
import Iconify from "../../components/Iconify";
import CustomModal from "../../components/modal/CustomModal";
import { WaitListForm } from "../../components/forms";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { ResponseCard } from "../../components/cards";

const StyledTypeAnimation = styled(TypeAnimation)(({ theme }) => ({
    color: 'white',
    background: `linear-gradient(180deg, transparent 82%, ${alpha(theme.palette.secondary.main, 0.3)} 0%)`
}));

function ComingSoon() {
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const [openModal, setOpenModal] = useState(false);
    const [openResponse, setOpenResponse] = useState(false);

    const { search } = useLocation();
    const navigate = useNavigate();

    const query = new URLSearchParams(search);
    const subscribe = query.get('subscribe');

    useEffect(() => {
        if (subscribe) {
            setOpenModal(true);
        }
    }, [subscribe]);

    useEffect(() => {
        if (openModal === false) {
            query.delete('subscribe');
            navigate({
                search: createSearchParams(query).toString(),
            }, { replace: true });
        }
    }, [openModal]);


    const handleCloseModal = () => {
        setOpenModal(false);
        setOpenResponse(true);
    };

    return (
        <Container maxWidth="lg">
            <CustomModal open={openResponse} setOpen={setOpenResponse} modalSize={matches ? "xs" : 'lg'}>
                <ResponseCard title='Thank you for subscribing to our waitlist.' subtitle={'we\'ll be hearing from us shortly.'} />
            </CustomModal>
            <CustomModal open={openModal} setOpen={setOpenModal} title="Subscribe to Waitlist" modalSize={matches ? "xs" : 'lg'}>
                <WaitListForm closeModal={handleCloseModal} />
            </CustomModal>
            <Grid container alignItems='center' justifyContent='center' flexDirection={matches ? 'row' : 'column-reverse'} spacing={2}>
                <Grid item sm={6} xs={12} sx={{ my: matches ? 0 : 5 }}>
                    <Typography variant="h2" sx={{ color: 'rgba(255,255,255,0.8)', fontWeight: 700 }} gutterBottom>
                        Get the best deal ever on website design and maintanace @ {' '}
                        <StyledTypeAnimation
                            cursor
                            sequence={['', 500, '₦5,000 monthly.']}
                            wrapper="span"
                        />
                    </Typography>
                    <Typography component="p" fontSize={17} sx={{ mr: matches ? 5 : 0, mb: 2, color: 'rgba(255,255,255,0.7)' }}>
                        You can focus on other business activities. We will do everything about the website. Also, check out our other essential services that will transform your business.
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        size='large'
                        endIcon={<Iconify icon='eva:arrow-ios-forward-fill' />}
                        onClick={() => setOpenModal(true)}
                    >
                        Join Our Waitlist
                    </Button>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <div style={{ height: matches ? 600 : 350 }} />
                </Grid>
            </Grid>
            <Box component='img' src="/static/images/coming-soon.png" loading="lazy" alt='...' sx={{ position: 'absolute', top: 60, right: 0, maxHeight: 'calc(100vh - 60px)', objectFit: 'contain' }} />
        </Container>
    );
}

export default ComingSoon;