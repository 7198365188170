import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { SWRConfig } from 'swr';

//@component
import * as serviceWorker from './serviceWorker';
import APIService from './service';
import { store } from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';

//@Styles
import 'react-multi-carousel/lib/styles.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'aos/dist/aos.css';
import 'simplebar/src/simplebar.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <Router>
      <Provider store={store}>
        <SWRConfig
          value={{
            // refreshInterval: 3000,
            revalidateOnFocus: true,
            revalidateOnReconnect: true,
            fetcher: (url) => APIService.fetcher(url),
          }}
        >
          <App />
        </SWRConfig>
      </Provider>
    </Router>
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

reportWebVitals();
