import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function HowTo(props) {
    const {items} = props;
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [expanded, setExpanded] = useState('panel0');

    const handleChange = (panel) => (_, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item container justifyContent={'center'} xs={12} md={6}>
            <Box  data-aos={'fade-right'}>
                <Typography variant='outlined'>HAVE QUESTIONS?</Typography>
                <Typography variant='h2' fontWeight='900' gutterBottom>How It Works</Typography>
                <Box component='img' src='/static/images/how-to.svg' sx={{width:'100%',height: 300}} />
            </Box>
        </Grid>
        <Grid item container alignItems="center" xs={12} md={6}>
          <Box  data-aos={'fade-left'}>
          {items?.map((item, index) => (
            <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={index}>
                <AccordionSummary
                    expandIcon={expanded === `panel${index}` ? <RemoveIcon /> : <AddIcon />}
                    aria-controls="panel1a-content"
                    id={`panel${index}`}
                >
                    <Typography variant='h4'>{item?.title}</Typography>
                </AccordionSummary>
                <AccordionDetails >
                    {item?.body?.map((body, bodyIndex) => (
                        <Typography variant='body1' key={bodyIndex} gutterBottom>
                            {body}
                        </Typography>
                    ))}
                </AccordionDetails>
            </Accordion>
          ))}
          </Box>
        </Grid>
        </Grid>
    </Box>
  );
}

export default HowTo;

HowTo.defaultProps = {
    items: []
};

HowTo.propTypes = {
    items: PropTypes.array
};