import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { MiniPackageCard, PackageCard, PreviewPackageCard } from '../cards';
import TicketSkeleton from '../skeleton/TicketSkeleton';
import Iconify from '../Iconify';
import { changeLayout, setLayoutState } from '../../store/reducer/serviceLayout';

const StyledBackground = styled('div')({
  height: 200,
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: 14,
});

const StyledHeader = styled('div')({
  position: 'absolute',
  top: 140,
});

const PackageDetail = (props) => {
  const { data, handleGoBack, handleCloseForm, dispatch } = props;
  const navigate = useNavigate();

  const handleSelection = (selected) => {
    let payload;
    if (data?.category === 'website') {
      payload = {
        ...data,
        subPackages: selected,
      };
    } else {
      payload = data;
    }

    if (selected.negotiable) {
      navigate('/dashboard/support', { state: payload, replace: true });
      return;
    }

    handleCloseForm(false);
    dispatch(setLayoutState(payload));
    navigate('/dashboard/services/checkout', { state: payload, replace: true });
    dispatch(changeLayout('checkout'));
  };

  return (
    <motion.div
      initial={{ scale: 0, display: 'none' }}
      animate={{ scale: data ? 1 : 0, display: data ? 'block' : 'none' }}
      layoutId={data?.id}
      transition={spring}
      style={{ width: '100%', position: 'relative', textAlign: 'start' }}
    >
      <StyledBackground
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.30)),
  url(${data?.featuredImage})`,
        }}
      />
      <StyledHeader>
        <Stack direction="row" alignItems="center">
          <IconButton onClick={handleGoBack}>
            <Iconify
              icon="eva:arrow-ios-back-fill"
              sx={{
                width: 35,
                height: 35,
                borderRadius: 80,
                padding: 0.4,
                color: 'white',
                backgroundColor: 'rgb(255 255 255 / 28%)',
              }}
            />
          </IconButton>
          <Typography sx={{ color: 'white' }}>Back</Typography>
        </Stack>
      </StyledHeader>
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" textTransform="capitalize">
          {data?.name}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {data?.desc}
        </Typography>
      </Box>
      {data?.subPackages?.length ? (
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} sx={{ mb: 4 }} spacing={3}>
          {data?.subPackages?.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <PackageCard data={item} handleSelection={handleSelection} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <MiniPackageCard data={data} handleSelection={handleSelection} />
      )}
    </motion.div>
  );
};

const PackageItem = (props) => {
  const { webPackages, otherPackages, handleSelected, selected } = props;
  return (
    <motion.div
      initial={{ scale: 0, display: 'none' }}
      animate={{ scale: selected ? 0 : 1, display: selected ? 'none' : 'block' }}
      transition={spring}
    >
      <Box marginBottom={10}>
        <Box sx={{ textAlign: 'start', maxWidth: 600, marginBottom: 5 }}>
          <Typography variant="h4" color="text.secondary" fontWeight="light">
            Web Site
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Our website plans appeal to your needs and desires. Our team of experts will design exactly the site that
            works for you, with you and as you want it to. Here are the options
          </Typography>
        </Box>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          {webPackages?.map((item, index) => (
            <Grid item xs={12} sm={4} md={4} key={index}>
              <motion.div layoutId={item.id} onClick={() => handleSelected(item)}>
                <PreviewPackageCard data={item} />
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box>
        <Box sx={{ textAlign: 'start', maxWidth: 600, marginBottom: 5 }}>
          <Typography variant="h4" color="text.secondary" fontWeight="light">
            Additional Service
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Your brand deserves a better touch of excellence. We understand that they can be expensive and
            time-consuming so we present you the unique opportunity to get them at almost no cost.
          </Typography>
        </Box>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          {otherPackages?.map((item, index) => (
            <Grid item xs={12} sm={4} md={4} key={index}>
              <motion.div layoutId={item.id} onClick={() => handleSelected(item)}>
                <PreviewPackageCard data={item} />
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </motion.div>
  );
};

const spring = {
  type: 'spring',
  stiffness: 600,
  damping: 30,
};

const PackageList = (props) => {
  const { dispatch, handleCloseForm, data = [] } = props;
  const [selected, setSelected] = useState(null);

  const webPackages = data?.filter((item) => item?.category === 'website');
  const otherPackages = data?.filter((item) => item?.category === 'other services');

  const handleSelected = (item) => {
    setSelected(item);
  };

  const handleGoBack = (item) => {
    setSelected(null);
  };

  return data?.length ? (
    <>
      <AnimatePresence>
        <PackageDetail
          data={selected}
          handleGoBack={handleGoBack}
          handleCloseForm={handleCloseForm}
          dispatch={dispatch}
        />
      </AnimatePresence>
      <PackageItem
        webPackages={webPackages}
        otherPackages={otherPackages}
        handleSelected={handleSelected}
        selected={selected}
      />
    </>
  ) : (
    <Grid container spacing={2}>
      {Array.from([1, 2, 3]).map((item) => (
        <Grid item key={item} xs={12} sm={4}>
          <TicketSkeleton />
        </Grid>
      ))}
    </Grid>
  );
};

export default PackageList;

PackageList.propTypes = {
  data: PropTypes.array,
  handleCloseForm: PropTypes.func,
  dispatch: PropTypes.func,
};
