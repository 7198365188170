import PropType from 'prop-types';
import toast, { Toaster } from 'react-hot-toast';
import { styled, alpha } from '@mui/material/styles';
import Card from '@mui/material/Card';
import { useTheme } from '@emotion/react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Iconify from '../Iconify';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  width: '100%',
  borderRadius: 10,
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
}));

const StyledLink = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(0.2),
  border: `solid 1px ${theme.palette.text.disabled}`,
  backgroundColor: theme.palette.secondary.lighter,
  borderRadius: 10,
  textAlign: 'center',
  justifyContent: 'space-between',
}));

const socials = [
  {
    name: 'Twitter',
    icon: 'foundation:social-twitter',
    link: 'https://twitter.com/intent/tweet?text=',
  },
  {
    name: 'Facebook',
    icon: 'foundation:social-facebook',
    link: 'https://www.facebook.com/sharer/sharer.php?u=',
  },
  {
    name: 'WhatsApp',
    icon: 'dashicons:whatsapp',
    link: 'https://wa.me/?text=',
  },
  {
    name: 'Linkedin',
    icon: 'brandico:linkedin-rect',
    link: 'https://www.linkedin.com/sharing/share-offsite/?url=',
  },
];

function ShareCard(props) {
  const { referralCode } = props;
  const theme = useTheme();
  const link = `${process.env.REACT_APP_DOMAIN}/account/signup?referralCode=${referralCode}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(referralCode).then(() => {
      toast('Copied to clipboard');
    });
  };

  function handleShare(obj) {
    const navUrl = obj.link + link;
    window.open(navUrl, '_blank');
  }

  return (
    <StyledCard elevation={0}>
      <Typography variant="h4">Share</Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-evenly">
        {socials?.map((social, index) => (
          <IconButton
            key={index}
            sx={{ bgcolor: alpha(theme.palette.secondary.main, 0.12) }}
            onClick={() => handleShare(social)}
          >
            <Iconify icon={social.icon} sx={{ color: theme.palette.secondary.main }} />
          </IconButton>
        ))}
      </Stack>
      <StyledCardContent sx={{ textAlign: 'center' }}>
        <StyledLink>
          <Typography variant="body1" color="secondary.dark" sx={{ px: 1 }} noWrap>
            {link}
          </Typography>
          <IconButton onClick={handleCopy} sx={{ bgcolor: alpha(theme.palette.secondary.main, 0.12) }}>
            <Iconify icon="bxs:copy" sx={{ color: theme.palette.secondary.main }} />
          </IconButton>
        </StyledLink>
      </StyledCardContent>
      <Toaster />
    </StyledCard>
  );
}

export default ShareCard;

ShareCard.propTypes = {
  referralCode: PropType.string,
};
