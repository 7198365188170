import PropType from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Iconify from '../../components/Iconify';
import { changeLayout, setOpenForm } from '../../store/reducer/serviceLayout';

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  height: '100%',
}));

function ServiceLayout() {
  const { layout, state } = useSelector((state) => state.serviceLayout);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    navigate(`/dashboard/services/${layout}`);
  }, [layout]);

  const handleOpenForm = () => {
    dispatch(setOpenForm(true));
  };

  const handleGoBack = () => {
    dispatch(setOpenForm(true));
    dispatch(changeLayout('all'));
  };

  return (
    <MainStyle>
      <Container maxWidth="lg">
        {layout === 'all' ? (
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
            <Typography variant="h4">Services</Typography>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenForm}>
              Add Service
            </Button>
          </Stack>
        ) : (
          <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
            <IconButton onClick={handleGoBack}>
              <Iconify
                icon="eva:arrow-ios-back-fill"
                sx={{
                  width: 30,
                  height: 30,
                  borderRadius: 10,
                  padding: 0.2,
                  color: 'white',
                  backgroundColor: 'rgb(0 0 0 / 28%)',
                }}
              />
            </IconButton>
            <Typography color="text.primary">{state?.category}</Typography>
            <Typography color="text.primary" fontWeight={state?.category === 'website' ? 'normal' : 'bolder'}>
              {state?.name}
            </Typography>
            {state?.category === 'website' && (
              <Typography color="text.primary" fontWeight="bolder">
                {state?.subPackages?.name}
              </Typography>
            )}
          </Breadcrumbs>
        )}
      </Container>
      <Outlet />
    </MainStyle>
  );
}

export default ServiceLayout;

ServiceLayout.propTypes = {
  setOpenForm: PropType.func,
};
