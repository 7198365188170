import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {Link as RouterLink} from 'react-router-dom';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

function Promo(props) {
    const {title, body, buttonText, buttonLink, buttonType, buttonIcon: Icon} = props;
  return (
    <Stack
        alignItems='center'
        justifyContent='center'
        spacing={5}
        paddingY={5}
        sx={{
            backgroundImage: 'url(/static/images/dot-pattern.svg)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left center',
            backgroundSize: 'contain'
        }}>
        <Box>
            <Typography
                variant='h3'
                align={'center'}
                gutterBottom
                sx={{ fontWeight: 700 }}
                data-aos='fade-up'
            >
                {title}
            </Typography>
            <Typography
                variant={'h6'}
                component={'p'}
                color={'text.secondary'}
                align={'center'}
                data-aos='fade-up'
            >
                {body}
            </Typography>
        </Box>
        <Button variant='contained' size='large' component={RouterLink} to={buttonLink} endIcon={Icon ? <Icon /> : null}>
            {buttonText}
        </Button>
    </Stack>
  );
}

export default Promo;

Promo.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonLink: PropTypes.string.isRequired,
    buttonIcon:  PropTypes.node,
    buttonType: PropTypes.string
};