import Container from "../../components/container";
// import useSWRFetch from "../../hooks/useSWRFetch";
import MiniHero from "./components/Hero/MiniHero";
import Pricings from "./components/Pricings";
import {Promo} from "./components/Promo";

function Pricing() {
  // const { data: packages } = useSWRFetch('/package/all');

  // console.log(packages);

    return (
      <>
        <MiniHero title='Pricing' image='/static/images/banner-page1.png' desc='Flexible pricing options' />
        <Container>
          <Pricings items={[]} titleVariant={'h3'} />
      </Container>
      <Container>
        <Promo title='Try WebOnTheGo Today' body='With our easy setup, you can get your business up and running in no time.' buttonText='Get Started' buttonLink='/signup' />
      </Container>
      </>
    );
  }
  
  export default Pricing;