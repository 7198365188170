import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from "../../../../components/container";
import Iconify from '../../../../components/Iconify';

const StyledHeader = styled('div')({
    position: 'absolute',
    top: 140,
  });

function PackageHero(props) {
    const {title, desc, id, navigate } = props;

    const color = id === 'other-services'? "#fb8500" : id === 'website' ? "#3d348b" :"#023e8a"

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        minHeight: 250,
        overflow: 'hidden',
        backgroundColor: color,
        backgroundImage: 'url(/static/images/dot-pattern.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'right center'
      }}
    >
        <Container paddingX={0} paddingY={5} maxWidth={{ sm: 1, md: 1236 }}>
            <Box maxWidth={600} marginBottom={5} marginX={{xs: 2, md: 0}} data-aos={'fade-up'}>
                <StyledHeader>
                    <Stack direction="row" alignItems="center" marginY={2}>
                        <IconButton component={RouterLink} to='/pricing'>
                            <Iconify
                            icon="eva:arrow-ios-back-fill"
                            sx={{
                                width: 35,
                                height: 35,
                                borderRadius: 80,
                                padding: 0.4,
                                color: 'white',
                                backgroundColor: 'rgb(255 255 255 / 28%)',
                            }}
                            />
                        </IconButton>
                        <Typography sx={{ color: 'white' }}>Pricing</Typography>
                    </Stack>
                </StyledHeader>
                <Typography variant="h2" color="common.white" sx={{ fontWeight: 700, textTransform: 'capitalize' }}>
                    {title}
                </Typography>
                <Typography variant="h6" component="p" color="rgb(255 255 255 / 78%)">
                    {desc}
                </Typography>

            </Box>
        </Container>
    </Box>
  )
}

export default PackageHero;

PackageHero.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    id: PropTypes.string,
    navigate: PropTypes.func
};