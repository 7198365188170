import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

function Contact(props) {
    const {  title, desc, image, items } = props;
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
      defaultMatches: true,
    });

    const LeftSide = () => (
        <List disablePadding>
          {items.map((item, index) => (
            <ListItem
              key={index}
              disableGutters
              data-aos="fade-up"
              data-aos-delay={index * 300}
              data-aos-offset={100}
              data-aos-duration={600}
            >
              <ListItemAvatar>
                <Box
                  component={Avatar}
                  variant='circular'
                  color={theme.palette.secondary.main}
                  bgcolor={`${theme.palette.secondary.lighter}`}
                >
                  {item.icon}
                </Box>
              </ListItemAvatar>
              <ListItemText primary={item.title} secondary={item.subtitle} secondaryTypographyProps={{color: 'text.secondary'}}/>
            </ListItem>
          ))}
        </List>
      );

  return (
    <Box>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item container alignItems="center" xs={12} md={6}>
            <Typography
              variant="h4"
              data-aos={'fade-up'}
              gutterBottom
              sx={{
                fontWeight: 700,
              }}
            >
             {title}
            </Typography>
            <Typography data-aos={'fade-up'} gutterBottom>
              {desc}
            </Typography>
            <LeftSide />
        </Grid>
        <Grid item container justifyContent={'center'} data-aos={'fade-left'} xs={12} md={6}>
          <Box
            component={LazyLoadImage}
            effect="blur"
            src={image}
            width={1}
            height={1}
            maxWidth={400}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Contact;

Contact.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    image: PropTypes.string,
    items: PropTypes.array,
};