import PropType from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import Iconify from '../Iconify';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  minHeight: 200,
  borderRadius: 16,
  cursor: 'pointer',
  transition: 'all 200ms ease-in',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

PreviewPackageCard.propTypes = {
  data: PropType.object,
};

function PreviewPackageCard(props) {
  const { data } = props;

  return (
    <StyledCard elevation={1}>
      <CardMedia src={data?.featuredImage} alt="..." component="img" height="100" />
      <CardContent sx={{ textAlign: 'start' }}>
        <Typography variant="h5" lineHeight={1.2} textTransform="capitalize" gutterBottom>
          {data?.name}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {data?.desc}
        </Typography>
      </CardContent>
    </StyledCard>
  );
}

export default PreviewPackageCard;
