import PropType from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Iconify from '../Iconify';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  backgroundImage: "url('/static/images/card-pattern7.png')",
  backgroundRepeat: 'repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'bottom right',
}));

const StyleCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  height: '100%',
  color: 'white',
}));

const CustomButton = styled(Button)(({ theme }) => ({
  maxWidth: 130,
  marginBottom: 5,
  backgroundColor: 'white',
  color: theme.palette.primary.main,
  boxShadow: `3px 4px 9px 0px ${alpha(theme.palette.secondary.main, 0.5)}`,
  '&:hover': {
    color: 'white',
    bgcolor: 'rgba(255, 255, 255, 0.20)',
  },
}));

ContactCard.propTypes = {
  setOpenForm: PropType.func,
};

function ContactCard(props) {
  const { setOpenForm } = props;

  return (
    <StyledCard onClick={() => setOpenForm(true)} variant="outlined">
      <StyleCardContent>
        <Typography variant="h5" sx={{ color: 'white' }}>
          Do you still need our help?
        </Typography>
        <Typography variant="caption" gutterBottom>
          Send your compliant here
        </Typography>
        <CustomButton variant="contained">Contact Us</CustomButton>
      </StyleCardContent>
    </StyledCard>
  );
}

export default ContactCard;
