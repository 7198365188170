import PropType from 'prop-types';
import { motion } from 'framer-motion';
import { styled, useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Iconify from '../Iconify';

const StyledCard = styled(Card)(({ theme }) => ({
  minHeight: 200,
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  cursor: 'pointer',
  transition: 'all 200ms ease-in',
  '&:hover': {
    transform: 'scale(1.03)',
  },
  '&:active': {
    transform: 'scale(0.9)',
  },
}));

const StyleCardContent = styled(CardContent)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));

function DocumentCard(props) {
  const { icon, name, desc } = props;
  const theme = useTheme();

  return (
    <StyledCard>
      <StyleCardContent>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 360, scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 260,
            damping: 20,
          }}
        >
          <Iconify
            icon={icon}
            sx={{
              backgroundColor: theme.palette.secondary.lighter,
              color: theme.palette.secondary.main,
              width: 70,
              height: 70,
              padding: 2,
              borderRadius: 50,
              marginBottom: 1,
              marginTop: 1,
            }}
          />
        </motion.div>
        <Typography variant="h5" lineHeight={1.2} gutterBottom>
          {name}
        </Typography>
        <Typography variant="body1" textAlign="center" color="text.secondary">
          {desc}
        </Typography>
      </StyleCardContent>
    </StyledCard>
  );
}

export default DocumentCard;

DocumentCard.propTypes = {
  icon: PropType.string,
  name: PropType.string,
  desc: PropType.string,
};
