import PropTypes from 'prop-types';
import Carousel from 'react-material-ui-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { alpha, styled, useTheme } from '@mui/material/styles';
import TypeAnimation from 'react-type-animation';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '../../../../components/container';
import Iconify from '../../../../components/Iconify';
import CustomButton from '../../../../components/customButton';

const StyledTypeAnimation = styled(TypeAnimation)(({ theme }) => ({
  color: theme.palette.secondary.main,
  background: `linear-gradient(180deg, transparent 82%, ${alpha(theme.palette.secondary.main, 0.3)} 0%)`
}));

const Hero = (props) => {
  const { items } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const LeftSide = ({ title, keyWord, desc, button, buttonLink, downloadButton }) => (
    <Box>
      <Box marginBottom={2}>
        <Typography variant="h2" color="text.primary" sx={{ fontWeight: 700 }}>
          {title}{" "}
          <StyledTypeAnimation
            cursor
            sequence={['', 500, keyWord]}
            wrapper="span"
          />
        </Typography>
      </Box>
      <Box marginBottom={3}>
        <Typography variant="h6" component="p" color="text.secondary">
          {desc}
        </Typography>
      </Box>
      {downloadButton ? <Stack direction='row' alignItems='center' spacing={2}>
        <CustomButton image='/static/images/google-play.svg' />
        <CustomButton image='/static/images/apple-store.svg' />
      </Stack> : <Button component="a"
        endIcon={<Iconify icon='eva:arrow-ios-forward-fill' />} href={buttonLink} variant="contained" color="primary" size="large">
        {button}
      </Button>}

    </Box>
  );

  const RightSide = ({ image }) => (
    <Box
      sx={{
        height: { xs: 'auto', md: 1 },
        '& img': {
          objectFit: 'cover',
        },
        '& .lazy-load-image-loaded': {
          height: 1,
          width: 1,
        },
      }}
    >
      <Box
        component={LazyLoadImage}
        effect="blur"
        src={image}
        height={{ xs: 'auto', md: 1 }}
        maxHeight={{ xs: 300, md: 1 }}
        width={1}
        maxWidth={1}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      <Carousel interval={10000} autoPlay={isMd} indicators={false} swipe={false} sx={{ width: '100%' }}>
        {items?.map((item, index) => (
          <Container key={index} paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
            <Box
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              position={'relative'}
              minHeight={{ md: 600 }}
            >
              <Box
                width={1}
                order={{ xs: 2, md: 1 }}
                display={'flex'}
                alignItems={'center'}
              >
                <Container>
                  <LeftSide {...item} />
                </Container>
              </Box>
              {isMd && <Box
                sx={{
                  flex: { xs: '0 0 100%', md: '0 0 50%' },
                  position: 'relative',
                  maxWidth: { xs: '100%', md: '50%' },
                  order: { xs: 1, md: 2 },
                }}
              >
                <Box
                  sx={{
                    width: { xs: 1, md: '50vw' },
                    height: '100%',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        overflow: 'hidden',
                        left: '0%',
                        width: 1,
                        height: 1,
                        position: { xs: 'relative', md: 'absolute' },
                        clipPath: {
                          xs: 'none',
                          md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                        },
                        shapeOutside: {
                          xs: 'none',
                          md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                        },
                      }}
                    >
                      <RightSide {...item} />
                    </Box>
                  </Box>
                </Box>
              </Box>}
            </Box>
          </Container>
        ))}
      </Carousel>
      <Divider />
    </Box>
  );
};

export default Hero;

Hero.propTypes = {
  items: PropTypes.array
};