import PropType from 'prop-types';
import { useState } from 'react';
import { usePaystackPayment } from 'react-paystack';
import toast, { Toaster } from 'react-hot-toast';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DebitCard from '../cards/DebitCard';
import EmptyCard from '../cards/EmptyCard';
import CustomModal from '../modal/CustomModal';
import Iconify from '../Iconify';
import APIService from '../../service';
import LoadingBackdrop from '../loading/Backdrop';
import formatCurrency from '../../utils/formatCurrency';

DebitCardForm.propTypes = {
  profile: PropType.object,
  mutate: PropType.func,
};

function DebitCardForm(props) {
  const { profile, mutate } = props;
  const [debitCard, setDebitCard] = useState(profile?.debitCard);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const config = {
    reference: `LINK_${new Date().getTime().toString()}`,
    email: profile?.emailAddress,
    firstname: profile?.username,
    phone: profile?.phoneNumber?.replace('+234', '0'),
    // eslint-disable-next-line radix
    amount: parseInt(process.env.REACT_APP_LINK_DEBITCARD_CHARGE) * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    channels: ['card'],
  };

  const initializePayment = usePaystackPayment(config);

  const handleAddDebitCard = () => {
    setOpenModal(true);
  };

  const openPayStackModel = () => {
    setOpenModal(false);
    initializePayment(onSuccess, onClose);
  };

  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    setLoading(true);
    const response = APIService.post('/transaction/create', reference);
    toast.promise(response, {
      loading: 'Linking Debit Card...',
      success: (res) => {
        setDebitCard(res.data);
        mutate();
        setLoading(false);
        return 'DebitCard was linked successfully!';
      },
      error: (err) => {
        setLoading(false);
        return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
      },
    });
  };

  // you can call this function anything
  const onClose = () => {
    setLoading(false);
  };

  return (
    <>
      {loading && <LoadingBackdrop open={loading} setOpen={setLoading} />}
      <CustomModal open={openModal} setOpen={setOpenModal} title="Link Your DebitCard" modalSize="xs">
        <Box>
          <Typography variant="subtitle1" color="text.secondary">
            This will enable auto debit when your service expires.
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            You will be charged {formatCurrency(process.env.REACT_APP_LINK_DEBITCARD_CHARGE)} to link your card.
          </Typography>
          <Button
            variant="outlined"
            size="large"
            endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
            onClick={openPayStackModel}
          >
            Continue
          </Button>
        </Box>
      </CustomModal>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Typography variant="h4">Debit Card</Typography>
          <Typography variant="body2" color="text.secondary">
            This will enable auto debit when your service expires
          </Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          {debitCard ? (
            <DebitCard debitCard={debitCard} handleAction={openPayStackModel} />
          ) : (
            <EmptyCard title="Add DebitCard" handleAction={handleAddDebitCard} />
          )}
        </Grid>
      </Grid>
      <Toaster />
    </>
  );
}

export default DebitCardForm;
