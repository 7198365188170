import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from "../../../../components/container";

function CenterHero(props) {
    const {title, overlineText, desc} = props;
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        minHeight: 250,
        overflow: 'hidden',
        bgcolor: 'alternative.main'
      }}
    >
        <Container paddingX={0} paddingY={10} maxWidth={{ sm: 1, md: 1236 }}>
            <Stack alignItems='center' justifyContent='center' textAlign='center'>
                <Typography variant='overline'>{overlineText}</Typography>
                <Typography variant='h1'>{title}</Typography>
                <Typography variant='h5' fontWeight='light' color='text.secondary'>{desc}</Typography>
            </Stack>
        </Container>
        </Box>
  )
}

export default CenterHero;

CenterHero.propTypes = {
    overlineText: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string
}