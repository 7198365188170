import PropType from 'prop-types';
import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from '../../../components/container';
import TopBar from './Topbar';
import Footer from './Footer';
import Sidebar from './Sidebar';
import pages from './pages';
import CookieModal from '../../../components/modal/CookieModal';

function LandingLayout() {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <>
      <AppBar
        position={'sticky'}
        sx={{
          top: 0,
          bgcolor: 'background.default',
        }}
        elevation={trigger ? 1 : 0}
      >
        <Container paddingY={1}>
          <TopBar pages={pages} onSidebarOpen={handleSidebarOpen} pathname={pathname} />
        </Container>
      </AppBar>
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant='temporary'
        pages={pages}
        pathname={pathname}
      />
      <main>
        <Outlet />
        <Divider />
        <CookieModal />

      </main>
      <Container paddingY={4}>
        <Footer />
      </Container>
    </>
  );
}

export default LandingLayout;