import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useSWRFetch from '../../hooks/useSWRFetch';
// components
import Page from '../../components/Page';
import { InvoiceList } from '../../components/list';

import Iconify from '../../components/Iconify';

function Invoices(props) {
  const { profile } = props;
  return (
    <Page title="Invoices" sx={{ height: '100%' }}>
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">Invoices</Typography>
        </Stack>
        <Box height="100%" marginTop={2}>
          <InvoiceList profile={profile} isFull />
        </Box>
      </Container>
    </Page>
  );
}

export default Invoices;
