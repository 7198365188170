import PropType from 'prop-types';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import toast, { Toaster } from 'react-hot-toast';
// Services
import APIService from '../../service';
import Iconify from '../Iconify';

const formSchema = Yup.object().shape({
  subject: Yup.string().required('Subject required'),
  message: Yup.string().required('Message is required'),
});

SupportForm.propTypes = {
  ticketSubjects: PropType.array,
  matches: PropType.bool,
  setTicket: PropType.func,
  setActiveSubject: PropType.func,
  setTicketModal: PropType.func,
  setTab: PropType.func,
  mutate: PropType.func,
  navigate: PropType.func,
  initialValues: PropType.object,
  setInitialValues: PropType.func,
  pathname: PropType.string,
};

function SupportForm(props) {
  const {
    matches,
    setTicket,
    setTicketModal,
    ticketSubjects,
    setActiveSubject,
    mutate,
    setTab,
    initialValues,
    setInitialValues,
    navigate,
    pathname,
  } = props;
  const [loading, setLoading] = useState();

  const formik = useFormik({
    initialValues: {
      subject: initialValues?.subject || '',
      message: initialValues?.message || '',
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      setLoading(true);
      const response = APIService.post('/support/create', values);

      toast.promise(response, {
        loading: 'Sending...',
        success: (res) => {
          setLoading(false);
          setFieldValue('message', '.');
          setTicket(res.data);
          mutate();
          setTicketModal(false);
          setActiveSubject('All');
          if (initialValues) {
            navigate(pathname, { replace: true });
          }
          setInitialValues(null);
          setTab(1);
          return 'Your compliant has been received!';
        },
        error: (err) => {
          setLoading(false);
          return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
        },
      });
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ width: '100%', textAlign: 'start' }}>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <InputLabel htmlFor="subject">
              <em>Select your subject</em>
            </InputLabel>
            <NativeSelect
              input={<OutlinedInput variant="outlined" {...getFieldProps('subject')} id="subject" />}
              id="subject"
            >
              {ticketSubjects?.slice(1)?.map((subject) => (
                <option key={subject} value={subject}>
                  {subject}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <TextField
            fullWidth
            label="Message"
            multiline
            rows={4}
            {...getFieldProps('message')}
            error={Boolean(touched.message && errors.message)}
            helperText={touched.message && errors.message}
          />
        </Stack>
        <LoadingButton
          sx={{ marginTop: 2 }}
          fullWidth={!matches}
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          endIcon={<Iconify icon="ic:sharp-send" />}
        >
          Submit
        </LoadingButton>
      </Form>
      <Toaster />
    </FormikProvider>
  );
}

export default SupportForm;
