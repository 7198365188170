import PropType from 'prop-types';
import { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { usePaystackPayment } from 'react-paystack';
import { DataGrid, GridToolbarContainer, GridFooter } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Drawer from '@mui/material/Drawer';
import { sentenceCase } from 'change-case';
import colorVariant from '../../utils/colorVariant';
import Label from '../Label';
import MoreMenu from '../menu/MoreMenu';
import Empty from '../empty';
import { fToNow } from '../../utils/formatTime';
import formatCurrency from '../../utils/formatCurrency';
import useSWRFetch from '../../hooks/useSWRFetch';
import { BankTransferCard, PreviewInvoiceCard } from '../cards';
import APIService from '../../service';
import CustomModal from '../modal/CustomModal';

const drawerWidth = 460;

const StyledDateGrid = styled(DataGrid)(({ theme }) => ({
  width: '100%',
  height: '100% !important',
  minHeight: 300,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  '& .MuiDataGrid-columnHeaders': {
    color: theme.palette.text.disabled,
    textTransform: 'uppercase',
    fontWeight: 400,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

function AdvanceToolbar({ isFull }) {
  return (
    <GridToolbarContainer>
      {!isFull && (
        <Typography variant="h6" color="text.secondary" fontSize={16} sx={{ mx: 0.5 }} gutterBottom>
          Recent Invoices
        </Typography>
      )}
    </GridToolbarContainer>
  );
}

function CustomFooter() {
  return <div />;
}

InvoiceList.defaultProps = {
  isFull: false,
};

InvoiceList.propTypes = {
  isFull: PropType.bool,
  profile: PropType.object,
};

function InvoiceList(props) {
  const { isFull, profile } = props;
  const [actionType, setActionType] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [paymentType, setPaymentType] = useState('debitcard');
  const [paymentReceived, setPaymentReceived] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [onlinePaymentResponse, setOnlinePaymentResponse] = useState(null);
  const [paymentProof, setPaymentProof] = useState('');
  const [errorStatus, setErrorStatus] = useState({
    field: '',
    message: '',
  });

  const [selected, setSelected] = useState(null);
  const { data, loading, mutate } = useSWRFetch('/invoice/single');
  const [filteredInvoices, setFilteredInvoices] = useState([]);

  const [config, setConfig] = useState({
    reference: `INVOICE_${new Date().getTime().toString()}`,
    email: profile?.emailAddress,
    firstname: profile?.username,
    phone: profile?.phoneNumber?.replace('+234', '0'),
    amount: parseInt(selected?.amount) * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    channels: ['card'],
  });

  const initializePayment = usePaystackPayment(config);

  useEffect(() => {
    if (data?.length) {
      const mappedData = data?.map((item) => {
        const subPackage =
          item?.service?.package?.category === 'website'
            ? item?.service?.package?.subPackages?.filter((p) => p?._id === item?.subPackage)[0]
            : null;
        return {
          ...item,
          subPackage,
        };
      });
      setFilteredInvoices(mappedData);
    }
  }, [data]);

  useEffect(() => {
    if (actionType === 'VIEW') {
      setOpenDetails(true);
    }
  }, [actionType]);

  useEffect(() => {
    if (selected) {
      setConfig((prev) => ({ ...prev, amount: parseInt(selected?.amount) * 100 }));
    }
  }, [selected]);

  const columns = [
    {
      field: 'invoiceId',
      headerName: 'Invoice Id',
      sortable: true,
      flex: 1,
      renderCell: (params) => (
        <StyledTypography variant="body1" noWrap>
          #{params?.value}
        </StyledTypography>
      ),
    },
    {
      field: 'amount',
      sortable: true,
      flex: 1,
      renderCell: (params) => <Typography>{formatCurrency(params?.value)}</Typography>,
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 1,
      renderCell: (params) => <StyledTypography variant="body1">{fToNow(params?.value)}</StyledTypography>,
    },
    {
      field: 'status',
      sortable: false,
      align: 'left',
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <Label variant="ghost" color={colorVariant(params?.value)}>
          {sentenceCase(params?.value)}
        </Label>
      ),
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      width: 50,
      disableColumnMenu: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          setSelected(params.row);
        };
        return <MoreMenu handleClick={onClick} setActionType={setActionType} setConfirm={setConfirm} type="invoice" />;
      },
    },
  ];

  const closeDetails = () => {
    setActionType('');
    setOpenDetails(false);
  };
  // const handleMoreAction = (arg) => {};

  const handleCheckout = () => {
    closeDetails();
    if (paymentType === 'debitcard') {
      if (!paymentReceived) {
        initializePayment(onSuccess, onClose);
      } else {
        handleStoreData(onlinePaymentResponse);
      }
    } else {
      setOpenTransferModal(true);
    }
  };

  const onSuccess = (reference) => {
    if (reference?.status === 'success') {
      setPaymentReceived(true);
      setOnlinePaymentResponse(reference);
      handleStoreData(reference);
    }
  };

  const handleTransferDone = () => {
    if (!paymentProof) {
      return setErrorStatus({
        field: 'paymentProof',
        message: 'Please upload your proof of payment',
      });
    }
    toast.success(
      "Thanks for your purchase. we'll update your payment status once we verify your transaction. this will not take long.",
      { duration: 10000 }
    );
    setPaymentProof('');
    setPaymentReceived(false);
    setOpenTransferModal(false);
  };

  const handleUploadedFile = async (data, id) => {
    setErrorStatus({
      field: '',
      message: '',
    });
    setPaymentProof(data?.secure_url || data?.url);
    //update invoice
    await APIService.update('/invoice/update', selected?.id, {
      paymentProof: data?.secure_url || data?.url,
    });
  };

  const handleStoreData = async (reference) => {
    try {
      const transaction = await APIService.post('/transaction/create', { ...reference, invoice: selected });

      if (transaction.status === 200) {
        await APIService.update('/invoice/update', selected?.id, {
          status: 'paid',
        });
      }
      setPaymentProof('');
      setPaymentReceived(false);
      setOpenTransferModal(false);
      mutate();
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const onClose = () => {};

  return (
    <>
      <StyledDrawer anchor="right" open={openDetails} onClose={closeDetails}>
        <DrawerHeader>
          <IconButton onClick={closeDetails}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <PreviewInvoiceCard
          data={selected}
          loading={loading}
          handleCheckout={handleCheckout}
          paymentType={paymentType}
          setPaymentType={setPaymentType}
        />
      </StyledDrawer>
      {openTransferModal && (
        <CustomModal
          open={openTransferModal}
          setOpen={handleTransferDone}
          title="Bank Transfer"
          modalSize="xs"
          background
        >
          <BankTransferCard
            amount={selected?.amount}
            handleDone={handleTransferDone}
            paymentProof={paymentProof}
            toast={toast}
            errorStatus={errorStatus}
            handleUploadedFile={handleUploadedFile}
          />
        </CustomModal>
      )}
      <StyledDateGrid
        rows={filteredInvoices}
        columns={columns}
        loading={loading}
        components={{
          Toolbar: () => <AdvanceToolbar isFull={isFull} />,
          NoRowsOverlay: () => <Empty text="No Invoice" />,
          Footer: isFull ? GridFooter : CustomFooter,
        }}
        disableColumnSelector={false}
        checkboxSelection={false}
      />
      <Toaster />
    </>
  );
}

export default InvoiceList;
