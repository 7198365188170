import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CallIcon from '@mui/icons-material/Call';
import Hero from './components/Hero/Hero';
import Container from '../../components/container';
import ThreePoints from './components/ThreePoints';
import AboutBottom from './components/AboutBottom/AboutBottom';
import Reviews from './components/Reviews';
import Pricings from './components/Pricings';
import Clients from './components/Clients';
import Iconify from '../../components/Iconify';
import HowTo from './components/HowTo';
import { Promo } from "./components/Promo";
import RequestACall from './components/RequestCall';

const banners = [
  {
    image: '/static/images/banner1.png',
    title: "Bring your business online with ",
    keyWord: "few little steps.",
    desc: "With webonthego, you can focus on other business activities. We will do everything about the website. Also, check out our other essential services that will transform your business.",
    button: "Get Started",
    buttonLink: "/account/signup"
  },
  {
    image: '/static/images/banner2.png',
    title: "Full control at your ",
    keyWord: "fingertips.",
    desc: "With our state of the arts mobile apps, managing your business becomes fun and easy.",
    button: "Download Now",
    downloadButton: true,
    buttonLink: "/account/signup"
  },
  {
    image: '/static/images/banner3.png',
    title: "Refer Once, Earn ",
    keyWord: "Forever.",
    desc: "Have a strong network or strong negotiation ability? Harness it and earn every time your referrals renew their subscriptions. It doesn’t get better than this! ",
    button: "Start Referring Now",
    buttonLink: "/account/signup"
  }
];

const mock = [
  {
    title: 'Built for you, whether developer or not',
    subtitle:
      'Webonthego is built with you in mind. Provided you have a brand that you want to showcase using a website, webonthego will help you easily communicate your brand values to the world.',
    icon: <Iconify icon='octicon:tools-16' />,
  },
  {
    title: 'Designed to be modern',
    subtitle:
      'We stay up to date with the latest design trends. The interface is modern, minimal, and beautiful and the system works with precision, ease and speed.',
    icon: (
      <Iconify icon='iconoir:design-pencil' />
    ),
  },
  {
    title: '24Hours Support',
    subtitle:
      'We have devoted our time and energy to render an all-round response. Our dedicated support team attends to all your queries promptly to help you stay focused in business while we do the rest.',
    icon: (
      <Iconify icon='bx:support' />
    ),
  },
];

const clients = [
  "/static/images/partners/total.png",
  "/static/images/partners/golden-tulip.png",
  "/static/images/partners/kusnap.png",
  "/static/images/partners/novotel.png",
  "/static/images/partners/grandclown.png",
  "/static/images/partners/hedobec.png",
  "/static/images/partners/sirajoil.png",
  "/static/images/partners/hdfresh.png",
];

const howitworks = [
  {
    title: "Consultation",
    body: ["Webonthego packages are available to choose from at the discretion of the users.", "To that the users make a choice that adequately reflects their needs though, our team are available to guide you in the choice process.  We provide professional assistance, and help you evaluate design preferences and other additional information that will result in the website you always wanted. Just give us a call, send an email or schedule an appointment. We will take it up and help you make the choices you need to make."]
  },
  {
    title: "Subscription",
    body: ["When the ideal package is decided, make payment depending on the package chosen.", "For the first payment, three months worth of the package fee is paid after which payment can be made on a monthly subscription basis. The three months upfront payment covers for the subscription for the first three months, so after making the payment, you are not expected to make any further payments until the three months interval elapses unless you choose to extend the period in advance."]
  },
  {
    title: "Development",
    body: ["Upon successful subscription, our team of professionals proceed to develop the website that suits your needs.", "We will collect the information we require from you and our team will develop the concepts to ensure that your website is engaging, attractive and productive. Depending on your package, we will develop the branded materials featured on the site.", ""]
  },
  {
    title: "Test & Launch",
    body: ["Upon completion, we test the website on multiple platforms and evaluate performance, then we launch it on your preferred domain name.", "webonthego packages include the cost of domain subscription, hosting, SSL certificate and other fundamentals that you would originally have paid extra money for."]
  }
];

function Home() {
  const theme = useTheme();

  return (
    <>
      <Box
        bgcolor={'background.default'}
        sx={{
          position: 'relative',
          '&::after': {
            position: 'absolute',
            content: '""',
            width: '30%',
            zIndex: 1,
            top: 0,
            left: '5%',
            height: '100%',
            backgroundSize: '18px 18px',
            backgroundImage: `radial-gradient(${alpha(theme.palette.primary.dark, 0.4)} 20%, transparent 20%)`,
            opacity: 0.2,
          },
        }}
      >
        <Box position={'relative'} zIndex={3}>
          <Hero items={banners} />
        </Box>
      </Box>

      <Container>
        <ThreePoints
          title='Develop anything your business needs.'
          desc='Build a beautiful, modern website with flexible, fully customizable, atomic MUI components.'
          image="/static/images/dashboard1.png"
          items={mock}
        />
      </Container>

      <Box bgcolor={'primary.dark'}>
        <Clients items={clients} />
      </Box>

      <Container>
        <AboutBottom title='About WebOnTheGo' image='/static/images/illustration1.svg' desc='Webonthego by iBrand Africa is a revolutionary service delivery which provides the solution to the core challenges faced by corporate entities in the digital world: the WEBSITE. It offers comprehensive website development and management services at almost no cost. With webonthego, you pay a monthly subscription, and our experts will design, develop and publish the ideal website for your brand.' link='/about' />
      </Container>

      <Container>
        <HowTo items={howitworks} />
      </Container>

      <Container>
        <Pricings items={[]} />
      </Container>

      <Box bgcolor={'primary.main'}>
        <Container>
          <Reviews />
        </Container>
      </Box>

      <Container>
        <RequestACall title='Request a call' body='We know that schedules can be inconvenient. Request a call now and our team will call you at the preferred time and date.' />
      </Container>
    </>
  );
}

export default Home;