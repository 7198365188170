import PropType from 'prop-types';
import { useState } from 'react';
import * as Yup from 'yup';
import { Form, useFormik, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
// material
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
// Third party
// Services
import APIService from '../../service';
// component
import Iconify from '../Iconify';
import Spacer from '../spacer';
import countries from '../../utils/countries';
import UploadInput from './input/UploadInput';

const businessTypes = [
  'Retailers',
  'Construction Businesses',
  'Home Service Professionals',
  'Financial Professionals',
  'Restaurants, Bars, and Night Clubs',
  'Wholesale Companies',
  'Non Profit (NGO)',
  'Transportation Companies',
  'Real Estate Companies',
  'Hospitality Companies',
  'Manufacturing Businesses',
  'Medical Offices',
  'Franchises',
  'Minority-Owned Businesses',
  'Other',
];

const roles = [
  'Executive (CFO)',
  'Manager',
  'Operations and production',
  'Chief executive officer (CEO)',
  'Chief operating officer (COO)',
  'Chief financial officer or controller (CFO)',
  'Chief marketing officer (CMO)',
  'Chief technology officer (CTO)',
  'President',
  'Vice president',
  'Executive assistants',
  'Marketing manager',
  'Product manager',
  'Project manager',
  'Finance manager',
  'Human resources manager (HR)',
  'Accountant',
  'Sales representative',
  'Customer service representative',
  'Administrative assistant',
];

const schema = Yup.object().shape({
  businessLogo: Yup.string(),
  aboutUsDoc: Yup.string(),
  businessName: Yup.string().required('Business Name is required'),
  businessDesc: Yup.string(),
  businessType: Yup.string().required('Business Type is required'),
  rcNumber: Yup.string(),
  domain: Yup.string(),
  role: Yup.string().required('Business Type is required'),
  country: Yup.string().required('Country is required'),
  address: Yup.string(),
});

function EditServiceForm(props) {
  const { data, mutate, closeEdit } = props;
  const [loading, setLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState({
    field: '',
    message: '',
  });

  const [initialValues] = useState({
    businessLogo: data?.document?.businessLogo || '',
    aboutUsDoc: data?.document?.aboutUsDoc || '',
    businessName: data?.businessName || '',
    businessDesc: data?.businessDesc || '',
    businessType: data?.businessType || 'Retailers',
    rcNumber: data?.rcNumber || '',
    domain: data?.domain || '',
    role: data?.role || 'Executive (CFO)',
    country: data?.country || 'Nigeria',
    address: data?.address || '',
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async () => {
      setLoading(true);
      const payload = {
        service: values,
        document: {
          businessLogo: values.businessLogo,
          aboutUsDoc: values.aboutUsDoc,
        },
      };

      delete payload.service.aboutUsDoc;
      delete payload.service.businessLogo;

      //   console.log('payload', payload);
      const response = APIService.update('/service/update', data?.id, payload);

      toast.promise(response, {
        loading: 'updating...',
        success: () => {
          setLoading(false);
          mutate();
          closeEdit();
          return 'Service updated successfully!';
        },
        error: (err) => {
          setLoading(false);
          return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
        },
      });
    },
  });

  const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  const handleUploadedFile = (data, id) => {
    const url = data?.secure_url || data?.url;
    setFieldValue(id, data?.secure_url);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Box sx={{ mb: 2 }}>
          <UploadInput
            fieldName="Your Business Logo (JPG, PNG)"
            id="businessLogo"
            value={values?.businessLogo}
            errorStatus={errorStatus}
            callback={handleUploadedFile}
            toast={toast}
          />
        </Box>
        <Box sx={{ mb: 2.5 }}>
          <UploadInput
            fieldName="About Us Document (PDF, DOCX)"
            id="aboutUsDoc"
            value={values?.aboutUsDoc}
            errorStatus={errorStatus}
            callback={handleUploadedFile}
            toast={toast}
          />
        </Box>
        <Stack spacing={1}>
          <Stack direction="row" alignItems="start" spacing={1}>
            <TextField
              fullWidth
              label="Business Name"
              disabled
              {...getFieldProps('businessName')}
              error={Boolean(touched.businessName && errors.businessName)}
              helperText={touched.businessName && errors.businessName}
            />
            <TextField fullWidth label="RC Number" {...getFieldProps('rcNumber')} helperText="optional" />
          </Stack>
          <TextField
            fullWidth
            label="Tell us more about your business?"
            {...getFieldProps('businessDesc')}
            multiline
            rows={4}
            helperText="Optional"
          />

          <Box sx={{ py: 1 }}>
            <FormControl fullWidth>
              <InputLabel htmlFor="businessType" sx={{ bgcolor: 'background.paper' }}>
                <em>Select your Business Type</em>
              </InputLabel>
              <NativeSelect
                input={<OutlinedInput variant="outlined" {...getFieldProps('businessType')} id="businessType" />}
                id="businessType"
              >
                {businessTypes?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box>
          <Box sx={{ py: 1 }}>
            <FormControl fullWidth>
              <InputLabel htmlFor="role" sx={{ bgcolor: 'background.paper' }}>
                <em>What's your Role?</em>
              </InputLabel>
              <NativeSelect input={<OutlinedInput variant="outlined" {...getFieldProps('role')} id="role" />} id="role">
                {roles?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box>
          {data?.category === 'website' && (
            <TextField
              fullWidth
              label="Already have a domain name?"
              {...getFieldProps('domain')}
              placeholder="eg: companyname.com"
              helperText="optional"
            />
          )}
          <Box sx={{ py: 1 }}>
            <FormControl fullWidth>
              <InputLabel htmlFor="country" sx={{ bgcolor: 'background.paper' }}>
                <em>Select your Country</em>
              </InputLabel>
              <NativeSelect
                input={<OutlinedInput variant="outlined" {...getFieldProps('country')} id="country" />}
                id="country"
              >
                {countries?.map((country) => (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box>
          <TextField fullWidth label="Office Address" {...getFieldProps('address')} helperText="Optional" />
          <LoadingButton fullWidth type="submit" size="large" variant="contained" color="primary" loading={loading}>
            Save Changes
          </LoadingButton>
        </Stack>
      </Form>
      <Toaster />
    </FormikProvider>
  );
}

export default EditServiceForm;

EditServiceForm.propTypes = {
  data: PropType.object,
  mutate: PropType.func,
  closeEdit: PropType.func,
};
