import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, cre, useNavigate } from 'react-router-dom';
import FuzzySearch from 'fuzzy-search';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Page from '../../components/Page';
import SupportForm from '../../components/forms/SupportForm';
import CustomModal from '../../components/modal/CustomModal';
import Spacer from '../../components/spacer';
import { ContactCard, TicketCard } from '../../components/cards';
import { SearchInput } from '../../components/forms';
import Scrollbar from '../../components/Scrollbar';
import CustomTabPanel from '../../components/CustomTabPanel';
import Iconify from '../../components/Iconify';
import { useSWRFetch } from '../../hooks';
import TicketSkeleton from '../../components/skeleton/TicketSkeleton';

const ColoredTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? theme.palette.primary.darker : theme.palette.primary.lighter,
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  // backgroundColor: theme.palette.background.paper,
  paddingBottom: theme.spacing(3),
  paddingTop: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    paddingRight: theme.spacing(3),
  },
  borderRadius: 16,
  height: '100%',
}));

const ticketSubjects = [
  'All',
  'Getting Started',
  'Account Management',
  'Web Service Management',
  'Add New Features',
  'Affiliate Funding',
  'Card Payment',
  'Price Enquires',
  'Others',
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Tickets = (props) => {
  const { tab, data } = props;
  return (
    <Scrollbar sx={{ height: '100%' }}>
      {data?.length
        ? data?.map((item, index) => <TicketCard key={index} data={item} />)
        : [0, 1, 2].map((item) => <TicketSkeleton key={item} />)}
    </Scrollbar>
  );
};

const tabs = ['Popular Tickets', 'My Tickets'];

Support.propTypes = {
  profile: PropTypes.object,
};

function Support(props) {
  const { profile } = props;
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const [ticket, setTicket] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [openForm, setOpenForm] = useState(false);
  const [tab, setTab] = useState(0);
  const [initialValues, setInitialValues] = useState(null);
  const { data: tickets } = useSWRFetch('/support/tickets?status=resolved&type=public');
  const { data: myTickets, mutate } = useSWRFetch('/support/single');
  const [activeSubject, setActiveSubject] = useState('All');
  const [filteredTickets, setFilteredTickets] = useState([]);

  useEffect(() => {
    if (tickets?.length) {
      handleFilterTicket(activeSubject);
    }
  }, [tickets, activeSubject, tab]);

  useEffect(() => {
    if (keyword.length > 3) {
      filterSearch(keyword);
    } else {
      handleFilterTicket(activeSubject);
    }
  }, [keyword]);

  useEffect(() => {
    if (state) {
      // console.log(state);
      setActiveSubject('Price Enquires');
      setTab(1);
      setInitialValues({
        subject: 'Price Enquires',
        message: `I want to make enquire of ${state?.name} service price.`,
      });
      setOpenForm(true);
    }
  }, [state]);

  const handleFilterTicket = (value) => {
    let data = [];
    let ticketList = [];
    if (tab) {
      ticketList = myTickets;
    } else {
      ticketList = tickets;
    }
    if (activeSubject === 'All') {
      data = ticketList;
    } else {
      data = ticketList?.filter((item) => item.subject === value);
    }
    setFilteredTickets(data);
  };

  const filterSearch = (text) => {
    let data = [];
    let ticketList = [];
    if (tab) {
      ticketList = myTickets;
    } else {
      ticketList = tickets;
    }
    const searcher = new FuzzySearch(ticketList, ['message', 'subject', 'ticketId'], {
      caseSensitive: false,
    });
    const result = searcher.search(text);
    setFilteredTickets(result);
  };

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  return (
    <Page title="Support" sx={{ height: '100%' }}>
      {openForm && (
        <CustomModal open={openForm} setOpen={setOpenForm} title="Create Ticket" modalSize="sm" background>
          <SupportForm
            matches={matches}
            setTicket={setTicket}
            setTicketModal={setOpenForm}
            profile={profile}
            ticketSubjects={ticketSubjects}
            setActiveSubject={setActiveSubject}
            mutate={mutate}
            setTab={setTab}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            pathname={pathname}
            navigate={navigate}
          />
        </CustomModal>
      )}
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <ColoredTypography variant="h4">Support</ColoredTypography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="fa6-regular:pen-to-square" />}
            onClick={() => setOpenForm(true)}
          >
            Raise a Ticket
          </Button>
        </Stack>
        <StyledGrid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <Typography variant="h3" lineHeight={1.2}>
              Search for a question
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Type your question or search keyword
            </Typography>
            <Spacer size={2} />
            <SearchInput keyword={keyword} setKeyword={setKeyword} />
            {matches && (
              <div>
                <List sx={{ my: 3 }}>
                  {ticketSubjects?.map((subject, index) => (
                    <ListItemButton
                      key={index}
                      onClick={() => setActiveSubject(subject)}
                      sx={{
                        bgcolor: activeSubject === subject ? 'primary.lighter' : 'transparent',
                        color: activeSubject === subject ? 'primary.main' : 'text.primary',
                      }}
                    >
                      <ListItemText primary={subject} />
                      <ListItemSecondaryAction>
                        <Iconify icon="eva:arrow-ios-forward-fill" sx={{ color: 'text.disabled' }} />
                      </ListItemSecondaryAction>
                    </ListItemButton>
                  ))}
                </List>
                <ContactCard setOpenForm={setOpenForm} />
              </div>
            )}
          </Grid>
          <Grid item sm={8} xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', borderRadius: 1 }}>
              <Tabs value={tab} onChange={handleChange} aria-label="tab">
                {tabs.map((item, index) => (
                  <Tab key={index} color="secondary" label={item} {...a11yProps(0)} />
                ))}
              </Tabs>
            </Box>
            <Tickets tab={tab} data={filteredTickets} />
          </Grid>
        </StyledGrid>
      </Container>
    </Page>
  );
}

export default Support;
