export default function colorVariant(status) {
  let color;
  switch (status) {
    case 'pending':
    case 'unpaid':
    case 'inactive':
      color = 'warning';
      break;
    case 'paid':
    case 'active':
    case 'credited':
    case 'resolved':
    case 'success':
      color = 'success';
      break;
    case 'declined':
    case 'denied':
    case 'debited':
    case 'closed':
      color = 'error';
      break;
    default:
      color = 'primary';
      break;
  }

  return color;
}
