import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  layout: 'all',
  openForm: false,
  state: null,
  newService: false,
};

export const serviceLayoutSlice = createSlice({
  name: 'serviceLayout',
  initialState,
  reducers: {
    changeLayout: (state, action) => {
      state.layout = action.payload;
    },
    setOpenForm: (state, action) => {
      state.openForm = action.payload;
    },
    setLayoutState: (state, action) => {
      state.state = action.payload;
    },
    setNewService: (state, action) => {
      state.newService = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { changeLayout, setOpenForm, setLayoutState, setNewService } = serviceLayoutSlice.actions;

export default serviceLayoutSlice.reducer;
