  import PropTypes from 'prop-types';
  import {Link as RouterLink } from 'react-router-dom';
  import Box from '@mui/material/Box';
  import Button from '@mui/material/Button';
  import Link from '@mui/material/Link';
  import { alpha, styled, useTheme } from '@mui/material/styles';
  import MenuIcon from '@mui/icons-material/Menu';
  import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';

  const StyledLink = styled(Link)(({theme}) => ({
    transition: 'color 400ms ease',
    '&:hover': {
      color: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.2) : alpha(theme.palette.common.black, 0.2),
      transform: 'scale(1.05)',
    },
  }));

  const TopBar = (props) => {
    const { pages, onSidebarOpen, pathname } = props;
    const theme = useTheme();
    const { mode } = theme.palette;

    return (
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={1}
      >
          <Logo title='Webonthego' dark={mode === 'dark'} titleColor={mode === 'dark' ? 'white' : 'primary.main'}  />
        <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
          {pages.map((page, index) => page.button ? <Button
            key={index}
            variant="contained"
            color="primary"
            component={RouterLink}
            endIcon={<Iconify icon='eva:arrow-ios-forward-fill' />}
            to={page.href}
            sx={{ml: 3}}
          >
            {page.name}
          </Button> : <Box key={index} marginLeft={3}>
            <StyledLink underline="none" component={RouterLink} to={page.href} color={pathname.includes(page.href) ? 'primary.light' : "text.primary"}>
              {page.name}
            </StyledLink></Box>)}
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'}>
          <Button
            onClick={() => onSidebarOpen()}
            aria-label="Menu"
            variant={'outlined'}
            sx={{
              borderRadius: 2,
              minWidth: 'auto',
              padding: 1,
              borderColor: alpha(theme.palette.divider, 0.2),
            }}
          >
            <MenuIcon />
          </Button>
        </Box>
      </Box>
    );
  };

  TopBar.propTypes = {
    onSidebarOpen: PropTypes.func,
    pages: PropTypes.array,
    pathname: PropTypes.string
  };

  export default TopBar;
