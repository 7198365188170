// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Overview',
    path: '/dashboard/overview',
    icon: getIcon('eva:pie-chart-outline'),
  },
  {
    title: 'Services',
    path: '/dashboard/services',
    icon: getIcon('ic:outline-web-stories'),
  },
  {
    title: 'Invoices',
    path: '/dashboard/invoices',
    icon: getIcon('nimbus:invoice'),
  },
  {
    title: 'Transactions',
    path: '/dashboard/transactions',
    icon: getIcon('icon-park-outline:transaction'),
  },
  {
    title: 'Affiliates',
    path: '/dashboard/affiliates',
    icon: getIcon('fluent:people-12-regular'),
  },
  {
    title: 'Profile',
    path: '/dashboard/profile',
    icon: getIcon('eva:person-outline'),
  },
  {
    title: 'Support',
    path: '/dashboard/support',
    icon: getIcon('eva:message-square-outline'),
  },
  {
    title: 'Settings',
    path: '/dashboard/settings',
    icon: getIcon('eva:settings-outline'),
  },
];

export default navConfig;
