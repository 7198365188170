import PropType from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import Iconify from '../Iconify';
import CustomModal from '../modal/CustomModal';
import { useState } from 'react';
import ShareCard from './ShareCard';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  minHeight: 250,
  borderRadius: 16,
  backgroundColor: theme.palette.secondary.main,
  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.20)),
  url('/static/images/banner3.jpg')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'bottom right',
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  height: '100%',
  color: 'white',
}));

const ReferralSpan = styled('div')(({ theme }) => ({
  fontWeight: 'bolder',
  fontSize: 16,
  textTransform: 'uppercase',
  backgroundColor: 'white',
  color: theme.palette.secondary.main,
  borderRadius: 5,
  paddingLeft: 5,
}));

const CustomButton = styled(Button)(({ theme }) => ({
  maxWidth: 300,
  marginBottom: 10,
  backgroundColor: 'white',
  color: theme.palette.primary.main,
  boxShadow: `3px 4px 9px 0px ${alpha(theme.palette.secondary.main, 0.5)}`,
  '&:hover': {
    color: 'white',
    bgcolor: 'rgba(255, 255, 255, 0.20)',
  },
}));

PromoCard.propTypes = {
  referralCode: PropType.string,
};

export default function PromoCard(props) {
  const { referralCode } = props;
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <CustomModal open={openModal} setOpen={setOpenModal} title="" modalSize="sm">
        <ShareCard referralCode={referralCode} />
      </CustomModal>
      <StyledCard>
        <StyledCardContent>
          <Typography variant="h2" fontWeight={900}>
            Quick cash!
          </Typography>
          <Typography variant="h5" sx={{ mb: 3, lineHeight: 1.2, fontWeight: 'light' }}>
            Get Paid <b>₦500</b> for Referring Customers to Us Every Time They Renew Their Services.
          </Typography>
          <Stack direction="row" alignItems="flex-start">
            <CustomButton
              variant="contained"
              endIcon={<Iconify icon="dashicons:share" />}
              onClick={() => setOpenModal(true)}
            >
              Share Referral link
            </CustomButton>
          </Stack>
        </StyledCardContent>
      </StyledCard>
    </>
  );
}
