import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled, alpha } from '@mui/material/styles';

function CustomButton({ image, ...rest }) {
  return (
    <Box
      {...rest}
      component="button"
      sx={{
        backgroundColor: '#0C0D11',
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        border: 0,
        height: 50,
        width: 150,
        borderRadius: 1,
        cursor: 'pointer',
        boxShadow: `3px 4px 9px 0px ${alpha('#364F9E', 0.5)}`,
        transition: 'all 300ms ease-in',
        '&:hover': {
          transform: 'scale(1.02)',
        },
      }}
    />
  );
}

export default CustomButton;

CustomButton.propTypes = {
  image: PropTypes.string,
};
