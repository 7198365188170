import * as Yup from 'yup';
import { useState } from 'react';
// Third party
import toast, { Toaster } from 'react-hot-toast';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';

// Services
import APIService from '../../service';

const schema = Yup.object().shape({
  emailAddress: Yup.string().required('Email Address is required'),
});

function ForgottenPasswordForm() {
  const [loading, setLoading] = useState();

  const formik = useFormik({
    initialValues: {
      emailAddress: '',
    },
    validationSchema: schema,
    onSubmit: async () => {
      setLoading(true);
      const response = APIService.post('auth/send-verification-link', values);

      toast.promise(response, {
        loading: 'Sending...',
        success: () => {
          setLoading(false);
          return `We sent a verification link to your email address (${values.emailAddress}). open your mail and click on the link.`;
        },
        error: (err) => {
          setLoading(false);
          return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
        },
      });
    },
  });
  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
        <TextField
          fullWidth
          autoComplete="email-address"
          type="email"
          label="Email address"
          {...getFieldProps('emailAddress')}
          error={Boolean(touched.emailAddress && errors.emailAddress)}
          helperText={touched.emailAddress && errors.emailAddress}
          sx={{ marginBottom: 2 }}
        />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
          Forgotten Password
        </LoadingButton>
      </Form>
      <Toaster />
    </FormikProvider>
  );
}

export default ForgottenPasswordForm;
