  import {Link as RouterLink } from 'react-router-dom';
  import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled, alpha, useTheme } from '@mui/material/styles';
import Logo from '../../../components/Logo';

const otherLinks = [
  {
    name: 'Help Centre',
    href: 'help'
  },
  {
    name: 'FAQs',
    href: 'faqs'
  },
  {
    name: 'Privacy Policy',
    href: 'privacy-policy'
  },
  {
    name: 'Terms of Service',
    href: 'terms-of-service'
  },
  {
    name: 'Cookie Policy',
    href: 'cookie-policy'
  }
];

const StyledLink = styled(Link)(({theme}) => ({
  transition: 'color 400ms ease',
  '&:hover': {
    color: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.2) : alpha(theme.palette.common.black, 0.2),
    transform: 'scale(1.05)',
  },
}));

const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const year = new Date().getFullYear();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
            <Logo title='Webonthego' dark={mode === 'dark'} titleColor={mode === 'dark' ? 'white' : 'primary.main'} size={30} fontSize={16} />
          <Box display="flex" flexWrap={'wrap'} alignItems={'center'} justifyContent='center'>
            {otherLinks.map((page, index) => (
            <Box key={index} marginTop={1} marginRight={2}>
              <StyledLink
                underline="none"
                component={RouterLink}
                to={page.href}
                color="text.primary"
                variant={'subtitle2'}
              >
                {page.name}
              </StyledLink>
            </Box>
            ))}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
          &copy; WebOnTheGo. {year}. All rights reserved
        </Typography>
        <Typography
          align={'center'}
          variant={'body2'}
          color="text.secondary"
          component={'div'}
        >
          <div dangerouslySetInnerHTML={{ __html: 'All Rights Reserved. WebOnTheGo mark is a registered trademark of IBrand Africa Limited Company, LLC in the Nigeria and other countries. The “WebOnTheGo” logo is a registered trademark of <a href="ibrandafrica.com" target="_blank">ibrandafrica.com</a>, LLC in the Nigeria.' }} />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;