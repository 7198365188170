/**
 *
 * @param {Number} percent
 * @param {Number} amount
 * @returns Number
 */

export default function getPercentage(percent, amount) {
  return parseFloat((percent * amount) / 100);
}
