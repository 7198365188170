import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useSWRFetch from '../../hooks/useSWRFetch';
// components
import Page from '../../components/Page';
import { PackageList, ServiceCheckList, ServiceList } from '../../components/list';

// import Iconify from '../../components/Iconify';
import CustomModal from '../../components/modal/CustomModal';
import { setNewService, setOpenForm } from '../../store/reducer/serviceLayout';
import { useSWRConfig } from 'swr';

function Services() {
  const { openForm, newService, state } = useSelector((s) => s.serviceLayout);
  const [controlForm, setControlForm] = useState(openForm);
  const [controlNewService, setControlNewService] = useState(newService);
  const { data: packages } = useSWRFetch('/package/all');
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();

  // useEffect(() => {
  //   console.log('state', state);
  // }, []);

  useEffect(() => {
    setControlForm(openForm);
  }, [openForm]);

  useEffect(() => {
    setControlNewService(newService);
  }, [newService]);

  const handleCloseForm = (value = false) => {
    dispatch(setOpenForm(value));
  };

  const handleNewServiceClose = () => {
    dispatch(setNewService(false));
  };

  return (
    <Page title="Services" sx={{ height: '100%' }}>
      {openForm && (
        <CustomModal open={controlForm} setOpen={handleCloseForm} title="" modalSize="md">
          <Container maxWidth="md">
            <PackageList data={packages} dispatch={dispatch} handleCloseForm={handleCloseForm} />
          </Container>
        </CustomModal>
      )}
      {newService && (
        <CustomModal open={controlNewService} setOpen={handleNewServiceClose} title="" modalSize="sm">
          <Typography variant="h3" fontWeight="bolder">
            Let's help you setup your business
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Click on the box to add the following
          </Typography>
          <ServiceCheckList service={state} mutate={mutate} handleCloseModal={handleNewServiceClose} />
        </CustomModal>
      )}
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <ServiceList />
      </Container>
    </Page>
  );
}

export default Services;
