import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import formatCurrency from '../../../../utils/formatCurrency';
import Iconify from '../../../../components/Iconify';

const mock = [
  {
    title: 'Additional Service',
    price: 10000,
    features: [
      'animation videos and promotions',
      'motion graphics & promotional videos',
      'content scripting & brand profiling',
      'social media management',
      'professional brand & product photography'
    ],
    isHighlighted: false,
    slug: '/pricing/other-services'
  },
  {
    title: 'Website',
    price: 5000,
    features: [
      "NO SETUP COSTS",
      "Free Domain Name (if not available)",
      "Domain Renewals",
      "Hosting",
      "Desktop, Tablet & Mobile Responsive",
      "24/7 site monitoring",
      "Face to Face Support"
  ],
    isHighlighted: true,
    slug: '/pricing/website'
  },
  {
    title: 'Mobile App',
    price: 20000,
    features: [
      'Support for all languages',
      'Release tracking',
      'Third-party integrations',
      'Unlimited members',
      'Cross-project visibility',
      'Custom Dashboards',
    ],
    isHighlighted: false,
    slug: '/pricing/mobile-app'
  },
];

const Pricing = (props) => {
  const {items, titleVariant} = props;
  const theme = useTheme();

  // console.log(items);

  return (
    <Box>
      <Box marginBottom={4} data-aos='fade-up'>
        <Typography
          variant={titleVariant}
          align={'center'}
          gutterBottom
          sx={{ fontWeight: 700 }}
        >
          Flexible and transparent pricing
        </Typography>
        <Typography
          variant={'h6'}
          component={'p'}
          color={'text.secondary'}
          align={'center'}
        >
        No matter your needs and budget, we have the ideal package to suit your needs.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              component={Card}
              height={1}
              display={'flex'}
              flexDirection={'column'}
              boxShadow={item.isHighlighted ? 4 : 0}
              data-aos='fade-up'
            >
              <CardContent
                sx={{
                  padding: 4,
                }}
              >
                <Box
                  marginBottom={4}
                  display={'flex'}
                  justifyContent={'space-between'}
                  flexWrap='wrap'
                >
                  <Typography variant={'h4'}>
                    <Box component={'span'} fontWeight={600}>
                      {item.title}
                    </Box>
                  </Typography>
                  <Stack direction='row' alignItems={'baseline'} spacing={1}>
                    <Typography variant={'subtitle2'} color={'text.secondary'}>
                      Starts at
                    </Typography>
                    <Typography variant={'h4'} color={'primary'}>
                      <Box component={'span'} fontWeight={600}>
                        {formatCurrency(item?.price)}
                      </Box>
                    </Typography>
                  </Stack>
                </Box>
                <Grid container spacing={1}>
                  {item.features.map((feature, j) => (
                    <Grid item xs={12} key={j}>
                      <Box
                        component={ListItem}
                        disableGutters
                        width={'auto'}
                        padding={0}
                      >
                        <Box
                          component={ListItemAvatar}
                          minWidth={'auto !important'}
                          marginRight={2}
                        >
                          <Iconify
                              icon="ant-design:check-circle-filled"
                              sx={{ color: item.isHighlighted ? theme.palette.primary.light : theme.palette.secondary.main }}
                            />
                        </Box>
                        <ListItemText primary={feature} />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
              <Box flexGrow={1} />
              <CardActions sx={{ justifyContent: 'flex-end', padding: 4 }}>
                <Button size={'large'} variant={'contained'} component={RouterLink} to={item.slug}>
                  Learn more
                </Button>
              </CardActions>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Pricing;

Pricing.defaultProps = {
  titleVariant: 'h4'
};

Pricing.propTypes = {
  items: PropTypes.array,
  titleVariant: PropTypes.string
};