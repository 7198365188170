import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Iconify from '../Iconify';
import formatCurrency from '../../utils/formatCurrency';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  borderRadius: 10,
  transition: 'all 200ms ease-in',
  '&:hover': {
    transform: 'scale(1.01)',
  },
}));

function PackageCard(props) {
  const { data, handleSelection } = props;

  const isRecommended = () => data?.name === 'premium plan';

  return (
    <StyledCard sx={{ bgcolor: isRecommended() ? 'secondary.main' : 'background.paper' }} elevation={4}>
      <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box sx={{ flexGrow: 1, textAlign: 'start' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography
              variant="h6"
              textTransform="capitalize"
              sx={{ color: isRecommended() ? 'white' : 'text.primary' }}
            >
              {data?.name}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h3" sx={{ color: isRecommended() ? 'white' : 'text.primary' }}>
              {formatCurrency(data?.price)}
            </Typography>
            <Typography variant="body2" sx={{ color: isRecommended() ? 'white' : 'text.disabled' }}>
              / {data?.interval || 'Monthly'}
            </Typography>
          </Stack>
          <Typography
            variant="subtitle2"
            fontWeight={300}
            lineHeight={1.4}
            sx={{ color: isRecommended() ? 'white' : 'text.secondary', my: 1 }}
          >
            {data?.desc}
          </Typography>
          <List dense>
            {data?.features?.map((item, index) => (
              <ListItem sx={{ py: 0 }} key={index}>
                <ListItemIcon sx={{ minWidth: 30 }}>
                  <Iconify
                    icon="ant-design:check-circle-filled"
                    sx={{ color: isRecommended() ? '#4ed027' : '#6eff43' }}
                  />
                </ListItemIcon>
                <ListItemText primary={item} sx={{ color: isRecommended() ? 'white' : 'text.primary' }} />
              </ListItem>
            ))}
          </List>
        </Box>
        <Button
          fullWidth
          size="large"
          variant={isRecommended() ? 'contained' : 'outlined'}
          color={isRecommended() ? 'secondary' : 'primary'}
          sx={{
            color: isRecommended() ? 'secondary.main' : 'primary.main',
            bgcolor: isRecommended() ? 'white' : 'transparent',
          }}
          onClick={() => handleSelection(data)}
        >
          Choose Plan
        </Button>
      </CardContent>
    </StyledCard>
  );
}

export default PackageCard;

PackageCard.propTypes = {
  data: PropType.object,
  handleSelection: PropType.func,
};
