import PropType from 'prop-types';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

// components
import { AuthCarousel } from '../../../components/carousel';
import Logo from '../../../components/Logo';

// ----------------------------------------------------------------------

function AuthLayout() {
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          }}
        >
          <AuthCarousel />
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} alignItems='center' square>
          <Logo
            title="WebOnTheGo"
            titleColor="primary.main"
            sx={{ justifyContent: 'end', flexGrow: 1, padding: 2 }}
          />
          <Outlet />
        </Grid>
      </Grid>
  );
}

export default AuthLayout;