import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { sentenceCase } from 'change-case';
import colorVariant from '../../utils/colorVariant';
import Label from '../Label';
import MoreMenu from '../menu/MoreMenu';
import Empty from '../empty';
import { fToNow } from '../../utils/formatTime';
import formatCurrency from '../../utils/formatCurrency';
import useSWRFetch from '../../hooks/useSWRFetch';
import Scrollbar from '../Scrollbar';
import formatDate from '../../utils/formatDate';

const StyledTypography = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));

function AffiliateList() {
  const [actionType, setActionType] = useState('');
  const [confirm, setConfirm] = useState(false);
  const { data, loading } = useSWRFetch('/auth/affiliates');
  const [filteredAffiliates, setFilteredAffiliates] = useState([]);

  useEffect(() => {
    if (data?.length) {
      // console.log(data);
      setFilteredAffiliates(data);
    }
  }, [data]);

  const handleMoreAction = (arg) => {};

  return (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      {data?.length ? (
        <List disablePadding sx={{ px: 0 }}>
          {filteredAffiliates?.map((user, index) => (
            <ListItem
              key={index}
              sx={{ bgcolor: 'background.paper', borderRadius: 2, my: 2 }}
              secondaryAction={
                <MoreMenu handleClick={handleMoreAction} setActionType={setActionType} setConfirm={setConfirm} />
              }
            >
              <ListItemAvatar>
                <Avatar alt={user?.username} src={user?.username} />
              </ListItemAvatar>
              <ListItemText
                primary={<StyledTypography variant="h5">{user?.username}</StyledTypography>}
                secondary={formatDate(user?.createdAt)}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Empty text="Click the invite button to start Referring your pals and earn as they purchase our services" />
      )}
    </Scrollbar>
  );
}

export default AffiliateList;
