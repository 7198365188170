import PropType from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink } from 'react-router-dom';

// material
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
// import Link from '@mui/material/Link';
// Third party
import toast, { Toaster } from 'react-hot-toast';
// Services
import APIService from '../../service';
// component
import Iconify from '../Iconify';
import Spacer from '../spacer';
import countries from '../../utils/countries';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const usernameExp = /^[a-z0-9]+$/i;

const sex = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'I rather not say',
    value: 'other',
  },
];

const signupSchema = Yup.object().shape({
  username: Yup.string()
    .matches(usernameExp, 'Enter a valid username without any special character')
    .trim()
    .lowercase()
    .min(4)
    .max(20)
    .required('Username is required'),
  emailAddress: Yup.string().email().required('Email Address is required'),
  phoneNumber: Yup.string().matches(phoneRegExp, 'Enter a valid phone number').required('Phone number is required'),
  gender: Yup.string().required('Gender is required'),
  referrer: Yup.string(),
  password: Yup.string().min(6).required('Password is required'),
});

function SignupForm(props) {
  const { mutate, referralCode } = props;
  const [loading, setLoading] = useState();
  const [countryCode, setCountryCode] = useState('+234');
  const [isValidUsername, setIsValidUsername] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    username: '',
    referrer: referralCode || '',
    emailAddress: '',
    phoneNumber: '',
    gender: 'male',
    password: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: signupSchema,
    validateOnBlur: true,
    onSubmit: async () => {
      setLoading(true);
      const payload = {
        ...values,
        phoneNumber: `${countryCode}${
          values.phoneNumber.charAt(0) === '0' ? values.phoneNumber.substring(1) : values.phoneNumber
        }`,
      };
      const response = APIService.post('auth/create', payload);

      toast.promise(response, {
        loading: 'Loading',
        success: (res) => {
          localStorage.setItem('accessToken', res?.data?.accessToken);
          localStorage.setItem('refreshToken', res?.data?.refreshToken);
          mutate();
          setLoading(false);
          return res?.data?.message;
        },
        error: (err) => {
          setLoading(false);
          return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
        },
      });
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleUsernameChange = async (e) => {
    try {
      setIsValidUsername(null);

      const hasError = Boolean(touched.username && errors.username);

      if (!hasError) {
        const response = await APIService.post('/auth/validate-username', { username: e.target.value });
        if (response.data?.status) {
          setIsValidUsername(true);
        }
      }
    } catch (err) {
      setIsValidUsername(false);
      toast.error(err?.response?.data?.message || err?.message || 'Something went wrong, try again.');
    }
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChangeCode = (e) => {
    setCountryCode(e.target.value);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Stack spacing={1} sx={{ marginBottom: 2 }}>
          <TextField
            fullWidth
            id="outlined-required"
            autoComplete="username"
            label="Username"
            {...getFieldProps('username')}
            onBlur={handleUsernameChange}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="mdi:at" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {isValidUsername === false ? (
                    <Iconify icon={'mdi:close-thick'} sx={{ color: 'red' }} />
                  ) : isValidUsername === true ? (
                    <Iconify icon={'mdi:check-bold'} sx={{ color: 'green' }} />
                  ) : null}
                </InputAdornment>
              ),
            }}
            onKeyPress={(e) => {
              const validReg = new RegExp(usernameExp).test(e.key);
              if (!validReg) {
                e.preventDefault();
              }
            }}
          />

          <TextField
            fullWidth
            autoComplete="email-address"
            type="email"
            label="Email address"
            {...getFieldProps('emailAddress')}
            error={Boolean(touched.emailAddress && errors.emailAddress)}
            helperText={touched.emailAddress && errors.emailAddress}
            sx={{ marginBottom: 2 }}
          />

          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <TextField
              select
              value={countryCode}
              onChange={handleChangeCode}
              sx={{ maxWidth: 80 }}
              SelectProps={{
                native: true,
              }}
            >
              {countries.map((option, index) => (
                <option key={index} value={option.code}>
                  {`${option.code} - ${option.name}`}
                </option>
              ))}
            </TextField>
            <TextField
              fullWidth
              label="Phone Number"
              {...getFieldProps('phoneNumber')}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              helperText={touched.phoneNumber && errors.phoneNumber}
            />
          </Box>

          <TextField
            fullWidth
            select
            label="Gender"
            {...getFieldProps('gender')}
            SelectProps={{
              native: true,
            }}
            error={Boolean(touched.gender && errors.gender)}
            helperText={touched.gender && errors.gender}
          >
            {sex.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>

          <TextField
            fullWidth
            autoComplete="referrer"
            label="Referral Code?"
            {...getFieldProps('referrer')}
            helperText="Optional"
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
          Create Account
        </LoadingButton>
      </Form>
      <Toaster />
    </FormikProvider>
  );
}

export default SignupForm;

SignupForm.propTypes = {
  mutate: PropType.func,
  referralCode: PropType.string,
};
