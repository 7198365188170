import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

function TicketSkeleton() {
  return (
    <Box marginBottom={4}>
      <Skeleton variant="rectangular" width={'100%'} height={150} />
      <Box sx={{ pt: 0.5 }}>
        <Skeleton />
        <Skeleton width="60%" />
      </Box>
    </Box>
  );
}

export default TicketSkeleton;
