import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';
import LoadingButton from '@mui/lab/LoadingButton';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Iconify from '../Iconify';
import formatCurrency from '../../utils/formatCurrency';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.primary.darker,
  borderRadius: 10,
  transition: 'all 200ms ease-in',
  '&:hover': {
    transform: 'scale(1.01)',
  },
}));
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 0,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: 'white',
}));

const Item = ({ keyName, value, bold, fontSize = 14 }) => (
  <ListItem
    divider
    secondaryAction={
      <StyledTypography sx={{ fontSize, fontWeight: bold ? 'bolder' : 'normal' }}>{value}</StyledTypography>
    }
  >
    <ListItemText
      primary={<StyledTypography sx={{ fontSize, fontWeight: bold ? 'bolder' : 'normal' }}>{keyName}</StyledTypography>}
    />
  </ListItem>
);

CheckoutCard.propTypes = {
  loading: PropType.bool,
  total: PropType.number,
  subTotal: PropType.number,
  amount: PropType.number,
  percentAmount: PropType.number,
  vat: PropType.number,
  initialValues: PropType.object,
  setInitialValues: PropType.func,
  selectedPackage: PropType.object,
  handleCheckout: PropType.func,
};

export default function CheckoutCard(props) {
  const {
    loading,
    total,
    amount,
    percentAmount,
    subTotal,
    vat,
    initialValues,
    setInitialValues,
    selectedPackage,
    handleCheckout,
  } = props;

  const handleChangePayment = (value) => {
    setInitialValues((prev) => ({ ...prev, paymentType: value }));
  };

  return (
    <StyledCard>
      <StyledCardContent>
        <Box sx={{ flexGrow: 1 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ p: 2, bgcolor: 'rgb(255 255 255 / 10%)', mb: 3 }}
          >
            <div>
              <StyledTypography variant="caption" textTransform="capitalize">
                {selectedPackage?.category}
              </StyledTypography>
              <StyledTypography variant="h6" textTransform="capitalize">
                {selectedPackage?.name} /{' '}
                <span style={{ fontSize: 13, fontWeight: 'lighter' }}>{selectedPackage?.interval}</span>
              </StyledTypography>
            </div>
            <StyledTypography variant="h4">{formatCurrency(amount)}</StyledTypography>
          </Stack>
          <FormControl sx={{ px: 2 }} fullWidth>
            <FormLabel id="paymentType" sx={{ color: 'rgb(255 255 255 / 80%)', fontSize: 14, mb: 1 }}>
              Select Your Payment Method
            </FormLabel>
            <ButtonGroup aria-labelledby="paymentType" variant="outlined" fullWidth>
              <Button
                startIcon={<Iconify icon="bi:credit-card" />}
                onClick={() => handleChangePayment('debitcard')}
                sx={{
                  borderWidth: initialValues.paymentType === 'debitcard' ? 2 : 1,
                  borderColor: initialValues.paymentType === 'debitcard' ? 'white' : 'rgb(255 255 255 / 60%)',
                  color: initialValues.paymentType === 'debitcard' ? 'white' : 'rgb(255 255 255 / 50%)',
                }}
              >
                Debit Card
              </Button>
              <Button
                startIcon={<Iconify icon="bx:transfer-alt" />}
                onClick={() => handleChangePayment('bank-transfer')}
                sx={{
                  borderWidth: initialValues.paymentType === 'bank-transfer' ? 2 : 1,
                  borderColor: initialValues.paymentType === 'bank-transfer' ? 'white' : 'rgb(255 255 255 / 60%)',
                  color: initialValues.paymentType === 'bank-transfer' ? 'white' : 'rgb(255 255 255 / 50%)',
                }}
              >
                Bank Transfer
              </Button>
            </ButtonGroup>
          </FormControl>
          <List sx={{ px: 0, mt: 2 }}>
            <Item keyName="Subtotal" value={formatCurrency(subTotal)} />
            <Item keyName={`VAT (${vat}%)`} value={formatCurrency(percentAmount)} />
            <Item keyName="Total" value={formatCurrency(total)} fontSize={16} bold />
          </List>
        </Box>
        <Box sx={{ mx: 2, mt: 3 }}>
          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            loading={loading}
            onClick={handleCheckout}
          >
            Checkout {formatCurrency(total || 0)}
          </LoadingButton>
        </Box>
      </StyledCardContent>
    </StyledCard>
  );
}
