import MiniHero from "./components/Hero/MiniHero";
import Container from "../../components/container";
import useSWRFetch from "../../hooks/useSWRFetch";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Iconify from "../../components/Iconify";
import { sentenceCase } from "change-case";
import { ContactForm } from "../../components/forms";
import { Contact } from "./components/Contact";


const contactList = [
  {
    title: 'Phone',
    subtitle: '+234-802-060-0000, +234 904 333 3394',
    icon: <Iconify icon='carbon:phone-filled' />,
  },
  {
    title: 'Email',
    subtitle: 'info@webonthego.co',
    icon: <Iconify icon='ic:round-email' />,
  },
  {
    title: 'Address',
    subtitle: 'We are a creative agency. We\'ve been in business since 2011',
    icon: <Iconify icon='fa-solid:location-arrow' />,
  },
];

const ticketSubjects = [
  // {
  //   title: 'Getting Started',
  //   icon: 'fontisto:hourglass-start'
  // },
  {
    title: 'Account Management',
    icon: 'bxs:user-account'
  },
  {
    title: 'Web Service Management',
    icon: 'mdi:web'
  },
  {
    title: 'Add New Features',
    icon: 'charm:layout-list'
  },
  {
    title: 'Affiliate Funding',
    icon: 'fa-solid:money-bill-wave-alt'
  },
  {
    title: 'Card Payment',
    icon: 'ant-design:credit-card-filled'
  }
];


const matchedSubjects = (subject, arr = []) => arr.filter((item) => item?.subject === subject) || [];

function Help() {
  const { data: tickets } = useSWRFetch('/support/tickets?status=resolved&type=public');

    return (
      <>
    <MiniHero title='What do you need?' image='/static/images/banner-page1.png' desc="Easily find solution to the issues your facing or contact us" />
    <Container>
      <Grid container spacing={5}>
        <Grid xs={12} sm={6} item>
        {ticketSubjects?.map((item, index) => (
          <Stack key={index}>
            <Stack direction='row' alignItems='center' spacing={2} marginTop={5}>
                <Box
                  component={Avatar}
                  variant='circular'
                  color='primary.main'
                  bgcolor='primary.lighter'
                >
                  <Iconify icon={item.icon} />
                </Box>
              <Typography variant='h4'>{item?.title}</Typography>
              </Stack>
              <Box>
                {matchedSubjects(item?.title, tickets)?.map((ticket, index) => (
                  <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id={`panel1a-header_${ticket?.id}`}
                  >
                    <Typography variant='h6'>{sentenceCase(ticket?.message)}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <div dangerouslySetInnerHTML={{ __html: ticket?.solution }} />
                  </AccordionDetails>
                </Accordion>
                ))}
              </Box>
          </Stack>
        ))}
        </Grid>
        <Grid xs={12} sm={6} marginY={4} item>
          <Box marginBottom={5}>            
            <Typography variant='h3'>
            Can't find the answer you need?
            </Typography>
            <Typography variant="body1" color='text.secondary' gutterBottom>
              Keep track of what's happening with your data, change permissions, and run reports against your data anywhere in the world. Keep track of what's happening with your data, change permissions.
            </Typography>
          </Box>
            <ContactForm ticketSubjects={ticketSubjects} />
        </Grid>
      </Grid>
    </Container>

    <Container paddingY={3}>
        <Contact
          title='Contact us'
          desc='Rather than worrying about switching offices every couple years, you can instead stay in the same location and grow-up from your shared coworking space to an office that takes up an entire floor.'
          items={contactList}
        image="/static/images/support.png"
        />
      </Container>
      </>
    );
  }
  
  export default Help;