import PropType from 'prop-types';
import toast, { Toaster } from 'react-hot-toast';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Iconify from '../Iconify';
import { useState } from 'react';
import formatCurrency from '../../utils/formatCurrency';
import WithdrawCard from './WithdrawCard';
import CustomModal from '../modal/CustomModal';
import APIService from '../../service';
import { useDispatch } from 'react-redux';
import LoadingBackdrop from '../loading/Backdrop';
import BankForm from '../forms/BankForm';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  minHeight: 250,
  borderRadius: 16,
  backgroundColor: theme.palette.primary.main,
  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.53)),
  url('/static/images/card-pattern1.png')`,
}));

const StyledContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: 'white',
  height: '100%',
}));

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.primary.main,
  boxShadow: `3px 4px 9px 0px ${alpha(theme.palette.secondary.main, 0.5)}`,
  '&:hover': {
    color: 'white',
    bgcolor: 'rgba(255, 255, 255, 0.20)',
  },
}));

function EarningCard(props) {
  const { wallet, mutate } = props;
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(wallet?.balance || 0);
  const [openModal, setOpenModal] = useState(false);
  const [openBankModal, setOpenBankModal] = useState(false);
  const [bank, setBank] = useState(props.bank);
  const dispatch = useDispatch();

  const handleRequestWithdraw = async (bank) => {
    //send to api here
    try {
      if (!bank) {
        setOpenBankModal(true);
        return;
      }
      setLoading(true);
      setOpenBankModal(false);
      const response = await APIService.post('/request/create', {
        type: 'withdrawal',
        amount: balance,
      });
      if (response.status === 200) {
        setBalance(parseInt(balance) - parseInt(balance));
        mutate();
        setLoading(false);
        setOpenModal(true);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message || 'Something went wrong, try again.');
    }
  };

  return (
    <>
      {openModal && (
        <CustomModal open={openModal} setOpen={setOpenModal} title="Withdraw" modalSize="xs">
          <WithdrawCard bank={bank} />
        </CustomModal>
      )}
      {loading && <LoadingBackdrop open={loading} setOpen={setLoading} />}
      {openBankModal && (
        <CustomModal open={openBankModal} setOpen={setOpenBankModal} title="" modalSize="sm">
          <Typography variant="h3" sx={{ mb: 3 }}>
            Add your bank information to complete your withdrawal request
          </Typography>
          <BankForm
            bank={bank}
            setBank={setBank}
            mutate={mutate}
            loading={loading}
            setLoading={setLoading}
            setOpenModal={setOpenBankModal}
            callback={handleRequestWithdraw}
          />
        </CustomModal>
      )}
      <StyledCard>
        <StyledContent>
          <Typography variant="overline" sx={{ color: 'white', fontWeight: 'lighter' }}>
            Earnings
          </Typography>
          <Box sx={{ textAlign: 'center', justifyContent: 'center', mt: 5, mb: 2 }}>
            <Typography variant="h6">Balance</Typography>
            <Typography variant="h3">{formatCurrency(balance)}</Typography>
          </Box>
          {balance ? (
            <CustomButton
              variant="contained"
              endIcon={<Iconify icon="akar-icons:arrow-forward-thick-fill" />}
              onClick={() => handleRequestWithdraw(bank)}
            >
              Withdraw
            </CustomButton>
          ) : null}
        </StyledContent>
        <Toaster />
      </StyledCard>
    </>
  );
}

export default EarningCard;

EarningCard.propTypes = {
  wallet: PropType.object,
  bank: PropType.object,
  mutate: PropType.func,
};
