import PropType from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// material
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
// Third party
import toast, { Toaster } from 'react-hot-toast';
// Services
import APIService from '../../service';
// component
import Iconify from '../Iconify';
import Spacer from '../spacer';

// ----------------------------------------------------------------------
LoginForm.propTypes = {
  mutate: PropType.func,
  state: PropType.object,
};

export default function LoginForm(props) {
  const { mutate, state } = props;
  const [loading, setLoading] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      setLoading(true);
      const response = APIService.post('/auth/login', values);

      toast.promise(
        response,
        {
          loading: 'Loading',
          success: (res) => {
            localStorage.setItem('accessToken', res?.data?.accessToken);
            localStorage.setItem('refreshToken', res?.data?.refreshToken);
            mutate();
            setLoading(false);
            navigate(0);

            return 'Login successful!';
          },
          error: (err) => {
            setLoading(false);
            return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
          },
        },
        {
          error: {
            duration: 6000,
          },
        }
      );
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    if (state?.emailAddress) {
      setFieldValue('username', state?.emailAddress);
    }
  }, [state]);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Stack spacing={2} sx={{ marginBottom: 2 }}>
          <TextField
            fullWidth
            id="outlined-required"
            autoComplete="username"
            label="Username/Email address"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Link component={RouterLink} variant="subtitle2" color="primary" to="/account/forgotten-password" underline="hover">
          Forgot password?
        </Link>

        <Spacer size={3} />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
          Sign In
        </LoadingButton>
      </Form>
      <Toaster />
    </FormikProvider>
  );
}
