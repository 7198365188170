import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AOS from 'aos';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import Router from './routes';
import { useProfile } from './hooks';
import { setAuth, setProfile, updateProfile } from './store/reducer/auth';
import { setLoading } from './store/reducer/lifeCircle';
import socket from './utils/socket';

function App() {
  const { isAuth, profile } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.lifeCircle);
  const [mountedComponent, setMountedComponent] = useState(false);
  const { data, loggedOut, loading: dataLoading, mutate: profileMutate } = useProfile();
  const dispatch = useDispatch();

  useEffect(() => {
    setMountedComponent(true);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      delay: 0,
      easing: 'ease-in-out',
      offset: 0,
    });
  }, []);

  // useEffect(() => {
  //   AOS.refresh();
  // }, [mountedComponent]);

  useEffect(() => {
    socket.on('connect', () => {
      // console.log('socket-user', socket.id);
    });
    dispatch(setLoading(dataLoading));

    if (data) {
      dispatch(setProfile(data));
      dispatch(setAuth(true));
      dispatch(setLoading(false));
    }
    if (loggedOut) {
      dispatch(setAuth(false));
      dispatch(setProfile(null));
    }
    // return () => socket.disconnect();
  }, [data, loggedOut, dataLoading]);

  return (
    <ThemeProvider>
      <ScrollToTop />
      <Router isAuth={isAuth} profile={profile} mutate={profileMutate} loading={loading} />
    </ThemeProvider>
  );
}

export default App;
