import PropType from 'prop-types';
import Grid from '@mui/material/Grid';
import toast, { Toaster } from 'react-hot-toast';
import { useState } from 'react';
import APIService from '../../service';
import { DocumentCard } from '../cards';
import DocumentUploadInput from '../forms/input/DocumentUploadInput';

const STAFF_LIMIT = 5;

function ServiceCheckList(props) {
  const { service, mutate } = props;
  const [loading, setLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState({
    field: '',
    message: '',
  });

  const [checklist, setChecklist] = useState([
    {
      name: 'Add Business Logo',
      fieldName: 'businessLogo',
      value: '',
      desc: 'Upload your business logo for review. (JPG, PNG) format only.',
      icon: 'ic:outline-business',
    },
    {
      name: 'Add Business Profile Document',
      fieldName: 'aboutUsDoc',
      value: '',
      desc: 'Upload your company profile for review. (Docx, Pdf) format only',
      icon: 'gg:file-document',
    },
    // {
    //   name: 'Add Staffs Account',
    //   fieldName: 'staffs',
    //   value: '',
    //   desc: `You can add upto ${STAFF_LIMIT} staffs for administrative access`,
    //   icon: 'icon-park-outline:send-email',
    // },
  ]);

  const handleUploadedFile = async (data, id) => {
    try {
      setLoading(true);
      setErrorStatus({
        field: '',
        message: '',
      });
      const value = data?.secure_url || data?.url;
      setChecklist(checklist.map((item) => (item.fieldName === id ? { ...item, value } : item)));
      const payload = {
        document: {
          [id]: value,
        },
      };

      await APIService.update('/service/update', service?.id, payload);
      mutate('/service/single');
    } catch (error) {
      return toast.error(
        error?.response?.data?.message || error?.message || 'Something went wrong! while updating service'
      );
    }
  };

  return (
    <Grid container spacing={2}>
      {checklist?.map((item, index) => (
        <Grid item xs={12} sm={6} key={index}>
          {item.fieldName === 'staffs' ? (
            <DocumentCard {...item} />
          ) : (
            <DocumentUploadInput
              {...item}
              id={item?.fieldName}
              errorStatus={errorStatus}
              callback={handleUploadedFile}
              toast={toast}
            />
          )}
        </Grid>
      ))}
      <Toaster />
    </Grid>
  );
}

export default ServiceCheckList;

ServiceCheckList.propTypes = {
  service: PropType.object,
  mutate: PropType.func,
};
