import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import { sentenceCase } from 'change-case';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Iconify from '../Iconify';
import formatCurrency from '../../utils/formatCurrency';
import Label from '../Label';
import colorVariant from '../../utils/colorVariant';
import Spacer from '../spacer';
// import calculateAmount from '../../utils/caculateAmount';

const StyledCard = styled(Card)(({ theme }) => ({
  paddingRight: 10,
  paddingLeft: 10,
  borderRadius: 10,
  transition: 'all 200ms ease-in',
  '&:hover': {
    transform: 'scale(1.01)',
  },
}));

function PreviewServiceCard(props) {
  const { data } = props;
  const packageDetail =
    data?.package?.category === 'website'
      ? {
          ...data?.subPackage,
          category: data?.package?.category,
          interval: data?.package?.interval,
          title: data?.package?.name,
        }
      : data?.package;

  return (
    <Box sx={{ p: 3 }}>
      <List
        subheader={
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography>Business Information</Typography>
            <Label variant="ghost" color={colorVariant(data?.status)}>
              {sentenceCase(data?.status)}
            </Label>
          </Stack>
        }
      >
        <ListItem divider sx={{ p: 0 }}>
          <ListItemText
            primary={
              <Typography variant="h4">
                {`${data?.businessName}`} <span style={{ fontSize: 14 }}>{data?.rcNumber}</span>
              </Typography>
            }
            secondary="business Name"
            secondaryTypographyProps={{ color: 'text.disabled' }}
          />
        </ListItem>
        <ListItem divider sx={{ p: 0 }}>
          <ListItemText
            primary={<Typography variant="body1">{data?.businessDesc}</Typography>}
            secondary="business Description"
            secondaryTypographyProps={{ color: 'text.disabled' }}
          />
        </ListItem>
        <ListItem divider sx={{ p: 0 }}>
          <ListItemText
            primary={<Typography variant="body1">{data?.businessType}</Typography>}
            secondary="business Type"
            secondaryTypographyProps={{ color: 'text.disabled' }}
          />
        </ListItem>
        <ListItem divider sx={{ p: 0 }}>
          <ListItemText
            primary={<Typography variant="body1">{data?.role}</Typography>}
            secondary="Position"
            secondaryTypographyProps={{ color: 'text.disabled' }}
          />
        </ListItem>
        <ListItem divider sx={{ p: 0 }}>
          <ListItemText
            primary={<Typography variant="body1">{`${data?.address} ${data?.country}`}</Typography>}
            secondary="business Address"
            secondaryTypographyProps={{ color: 'text.disabled' }}
          />
        </ListItem>
        {data?.domain ? (
          <ListItem divider sx={{ p: 0 }}>
            <ListItemText
              primary={<Typography variant="body1">{data?.domain}</Typography>}
              secondary="Website Domain Name"
              secondaryTypographyProps={{ color: 'text.disabled' }}
            />
          </ListItem>
        ) : null}
      </List>
      <Spacer size={5} />
      <StyledCard>
        <CardContent sx={{ textAlign: 'start', p: 0 }}>
          <Typography variant="body1">{packageDetail?.category}</Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography variant="h6" textTransform="capitalize">
              {packageDetail?.name}
            </Typography>
          </Stack>
          <Box sx={{ py: 1 }}>
            <Typography variant="subtitle2">
              {formatCurrency(packageDetail?.price)} / <span style={{ fontSize: 14 }}>{packageDetail?.interval}</span>
            </Typography>
          </Box>
          <List dense>
            {packageDetail?.features?.map((item, index) => (
              <ListItem sx={{ py: 0 }} key={index}>
                <ListItemIcon sx={{ minWidth: 30 }}>
                  <Iconify icon="ant-design:check-circle-filled" sx={{ color: '#4ed027' }} />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </StyledCard>
    </Box>
  );
}

export default PreviewServiceCard;

PreviewServiceCard.propTypes = {
  data: PropType.object,
};
