import PropType from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Iconify from '../Iconify';
import formatCurrency from '../../utils/formatCurrency';

MiniPackageCard.propTypes = {
  data: PropType.object,
  handleSelection: PropType.func,
};

function MiniPackageCard(props) {
  const { data, handleSelection } = props;

  return (
    <Box sx={{ my: 4 }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {data?.price ? (
          <Typography variant="h3">{formatCurrency(data?.price)}</Typography>
        ) : (
          <Typography variant="h5">Contact Us for Price</Typography>
        )}
        <Typography variant="body2">/ {data?.interval || 'Monthly'}</Typography>
      </Stack>
      {data?.note && <Alert severity="warning">{data?.note}</Alert>}

      {data?.features?.length ? (
        <List dense>
          {data?.features?.map((item, index) => (
            <ListItem key={index}>
              <ListItemIcon sx={{ minWidth: 30 }}>
                <Iconify icon="ant-design:check-circle-filled" sx={{ color: '#4ed027' }} />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      ) : null}

      <Button size="large" variant="contained" color="secondary" onClick={() => handleSelection(data)}>
        {data?.negotiable ? 'Contact Us' : 'Choose Plan'}
      </Button>
    </Box>
  );
}

export default MiniPackageCard;
