import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useSWRFetch from '../../hooks/useSWRFetch';
// components
import Page from '../../components/Page';

import Iconify from '../../components/Iconify';
import CustomModal from '../../components/modal/CustomModal';
import { AnalysisCard, EarningCard, PromoCard } from '../../components/cards';
import { InvoiceList, PackageList } from '../../components/list';
import { setOpenForm } from '../../store/reducer/serviceLayout';
import APIService from '../../service';

function Overview(props) {
  const { profile, mutate } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { openForm } = useSelector((state) => state.serviceLayout);
  const [openOnboarding, setOpenOnboarding] = useState(openForm);
  const { data: analysis } = useSWRFetch('/analysis/single');
  const { data: packages } = useSWRFetch('/package/all');

  useEffect(() => {
    if (!profile?.hasBoarded) {
      setOpenOnboarding(true);
      dispatch(setOpenForm(true));
    }
  }, []);

  const handleCloseForm = async (value = false) => {
    //update user onboarding here
    await APIService.update('/auth', 'update', { hasBoarded: true });
    setOpenOnboarding(false);
    dispatch(setOpenForm(value));
    mutate();
  };

  return (
    <Page title="Overview">
      <CustomModal open={openOnboarding} setOpen={handleCloseForm} title="" modalSize="full">
        <Container maxWidth="md" sx={{ textAlign: 'start' }}>
          <Box sx={{ my: 4 }}>
            <Typography variant="h2">Let's get you started!</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Select the package you want to setup for your business
            </Typography>
          </Box>
          <PackageList data={packages} dispatch={dispatch} handleCloseForm={handleCloseForm} />
        </Container>
      </CustomModal>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hey {profile?.username},
        </Typography>

        <Grid container spacing={2}>
          <Grid item container direction="column" sx={{ flexWrap: 'nowrap' }} sm={8} xs={12} spacing={2}>
            <Grid item container sm={8} xs={12} spacing={2}>
              <Grid item sm={4} xs={12}>
                <EarningCard wallet={profile?.wallet} bank={profile?.bank} mutate={mutate} />
              </Grid>
              <Grid item sm={8} xs={12}>
                <PromoCard referralCode={profile?.username} />
              </Grid>
            </Grid>
            <Grid item sm={12} xs={12}>
              <InvoiceList />
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Stack justifyContent="space-between" height="100%" spacing={3}>
              <AnalysisCard
                icon="ic:baseline-web-stories"
                color={theme.palette.primary.main}
                name="Services"
                count={analysis?.totalServices}
                route="/dashboard/services"
              />
              <AnalysisCard
                icon="fa6-solid:file-invoice-dollar"
                color="#FC2F00"
                name="Unpaid Invoices"
                count={analysis?.totalInvoices}
                route="/dashboard/invoices"
              />
              <AnalysisCard
                icon="fluent:people-12-filled"
                color="#FFBC0A"
                name="Affiliates"
                count={analysis?.totalAffiliates}
                route="/dashboard/affiliates"
              />
              <AnalysisCard
                icon="fluent:ticket-diagonal-20-filled"
                color="#C200FB"
                name="Tickets"
                count={analysis?.totalTickets}
                route="/dashboard/support"
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default Overview;
