const pages = [
    {
        name: 'Home',
        href: '/home'
    },
    {
        name: 'About Us',
        href: '/about'
    },
    {
        name: 'Pricing',
        href: '/pricing'
    },
    {
        name: 'Help',
        href: '/help'
    },
    {
        name: 'Sign in',
        href: '/account/login'
    },
    {
        name: 'Get Started',
        href: '/account/signup',
        button: true
    }
];
export default pages;