import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from "../../components/container";
import useSWRFetch from "../../hooks/useSWRFetch";
import PackageHero from "./components/Hero/PackageHero";
import { MiniPackageCard, PackageCard, PreviewPackageCard } from "../../components/cards";
import CustomModal from "../../components/modal/CustomModal";
import { changeLayout, setLayoutState } from "../../store/reducer/serviceLayout";
import Iconify from "../../components/Iconify";

const details = (id) => {
  switch (id) {
    case 'other-services':
      return {
        title: 'Additional Services',
        desc: 'Your brand deserves a better touch of excellence. We understand that they can be expensive and time-consuming so we present you the unique opportunity to get them at almost no cost.'
      };

    case 'website':
      return {
        title: 'Website',
        desc: 'Our website plans appeal to your needs and desires. Our team of experts will design exactly the site that works for you, with you and as you want it to. Here are the options'
      };

    case 'mobile-app':
      return {
        title: 'Coming Soon',
        desc: 'We are working tirelessly to the service up and running. stay in contact to get updates.'
      };


    default:
      return {
        title: '',
      };
  }
};

const StyledBackground = styled('div')({
  height: 200,
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: 14,
});

const StyledHeader = styled('div')({
  position: 'absolute',
  top: 140,
});

const PackageDetail = (props) => {
  const { data, handleGoBack, handleCloseForm, dispatch } = props;
  const navigate = useNavigate();

  const handleSelection = (selected) => {
    let payload;
    if (data?.category === 'website') {
      payload = {
        ...data,
        subPackages: selected,
      };
    } else {
      payload = data;
    }

    if (selected.negotiable) {
      navigate('/help', { state: payload, replace: true });
      return;
    }

    handleCloseForm(false);
    dispatch(setLayoutState(payload));
    navigate('/account/signup', { state: payload, replace: true });
    dispatch(changeLayout('checkout'));
  };

  return (
    <Box
      style={{ width: '100%', position: 'relative', textAlign: 'start' }}
    >
      {/* <StyledBackground
          sx={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.30)),
    url(${data?.featuredImage})`,
          }}
        /> */}
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" textTransform="capitalize">
          {data?.name}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {data?.desc}
        </Typography>
      </Box>
      {data?.subPackages?.length ? (
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} sx={{ mb: 4 }} spacing={3}>
          {data?.subPackages?.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <PackageCard data={item} handleSelection={handleSelection} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <MiniPackageCard data={data} handleSelection={handleSelection} />
      )}
    </Box>
  );
};

function Package() {
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();
  const { data: packages } = useSWRFetch(`/package/all?category=${id?.replace('-', ' ')}`);

  const handleSelected = (item) => {
    setSelected(item);
    setOpenModal(true);
  };

  return (
    <>
      <CustomModal open={openModal} setOpen={setOpenModal} title='' modalSize={id === 'website' ? 'md' : 'sm'}>
        <PackageDetail data={selected} handleCloseForm={setOpenModal} dispatch={dispatch} />
      </CustomModal>
      <PackageHero {...details(id)} id={id} />
      <Container>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          {packages?.map((item, index) => (
            <Grid item xs={12} sm={4} md={4} key={index}>
              <Box onClick={() => handleSelected(item)} data-aos={'fade-up'}>
                <PreviewPackageCard data={item} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default Package;