import Container from "@mui/material/Container";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CenterHero from "./components/Hero/CenterHero";
import { sentenceCase } from "change-case";

const faqs = [
  {
    id: 0,
    title: 'How does it work?',
    desc: 'It’s simple. Register on the site by clicking. Then proceed to make payment either on-site or offline. Then provide us with all the information you can spare and we’ll do the rest. <br><br> If you make your payment offline, please ensure to send us proof of the payment for verification and confirmation.',

  },
  {
    id: 1,
    title: 'How can I make payments?',
    desc: 'This website is integrated with our payment gateways so that payments can be made without leaving the site. If you decide to make your payment through other means however, ensure to use only the official account details provided below: <br><br>Bank Name: <strong>FIDELITY BANK</strong> <br><br>Account Number: <strong>5600879220</strong> <br><br>Account name: <strong>iBrand Africa LTD (Web On The Go)</strong> <br><br>Only make payments to the designated account details provided. iBrand Africa will not require you to make payments to any personal accounts. If you have any challenges, questions or need further clarification, kindly reach us on +234 904 333 3394 or email us at info@webonthego.co'
  },
  {
    id: 2,
    title: 'How much is the service worth?',
    desc: 'Our packages begin at NGN 5,000 monthly. Kindly follow this <a href="https://webonthego.co/pricing">Pricing</a> to view the offers and their respective prices.'
  },
  {
    id: 3,
    title: 'Do I need to make any extra payments?',
    desc: 'NO. Your monthly subscription is the only cost you have to pay<br/> Kindly disregard any individual or organization requesting any extra payment or personal information from you. iBrand Africa will not request any payment from you outside of your monthly subscription and any further products you may have chosen to subscribe for. Verify every information from us before you proceed to take any actions.'
  },
  {
    id: 4,
    title: 'What happens if I do no not pay the subscription on time?',
    desc: 'Your account will be temporarily suspended and your website will be shut down, pending when your payment is made. When payment is made and confirmed, the account is reactivated and your website goes live again.'
  },
  {
    id: 5,
    title: 'Will my account be deleted if I do not pay for the subscription for a long time?',
    desc: 'NO. Your account will remain dormant for as long as your subscription is not paid.'
  },
  {
    id: 6,
    title: 'Is my subscription automatically renewed?',
    desc: 'The subscription can be recurrent or manual depending on your preference. If you want to be automatically debited when payment is due, you can activate it on the payment gateway. This does not apply to manual payments though, unless you write to your bank to effect a deposit on your behalf on a particular day every month.'
  },
  {
    id: 7,
    title: 'Do I need to subscribe for a website package to be an affiliate partner?',
    desc: 'Affiliate partners do not necessarily need to subscribe for a website package. You can enjoy the website package without having to refer anyone. Similarly, you can be an affiliate without needing a website, thus you do not need to subscribe for one.'
  },
  {
    id: 8,
    title: 'How can I withdraw my referral bonuses?',
    desc: 'All you need to do to withdraw is request it. We have adapted the website to be as user friendly as possible. Just go the referral page on your dashboard and follow the prompts. <br /> Kindly note that you cannot make part withdrawal from your dashboard. When you request a withdrawal, all the funds accumulated in your dashboard until that time will be credited to your account after the procedure is complete.'
  },
  {
    id: 9,
    title: 'How does the affiliate program work?',
    desc: 'Registering as an affiliate partner enables you to benefit from WebOnTheGo by helping others satisfy their needs using WebOnTheGo. Through the affiliate program, you help people benefit from the packages and you get rewarded with NGN 500 monthly as long as the people you convince keep using our services.'
  },
  {
    id: 10,
    title: 'How much do I earn as an affiliate?',
    desc: 'Your earnings depend entirely on you. You get paid every time your converts renew their subscription and the payment continues as long as they use our services. The number of individuals you refer is unlimited so you can earn as much as you are able to muster. <br /> For instance, if you have 300 active referrals who actively renew their subscription monthly, you get paid NGN 500 per person per month renewed. That means NGN 150,000 monthly until the referrals stop renewing their subscription.'
  },
  {
    id: 11,
    title: 'Do I get special discounts or preferential treatments?',
    desc: 'WebOnTheGo offers website services at an unbelievably cheap rate. We do not offer any special discount or preferential treatment to any user. Any special offer we develop will be officially announced on our website and our social media handles. Kindly disregard any speculation that iBrand Africa Limited is offering any WebOnTheGo discount that is not verifiable on this website or social media handles. Contact us on info@webonthego.co or call 0904 3333 394 for clarification.'
  },
  {
    id: 12,
    title: 'Who is iBrand Africa?',
    desc: 'iBrand Africa (RC-1214587) is a leading branding, tech development and communications agency domiciled in Nigeria and provides services in branding, business development, websites and software development, business consultancy, promotions and advertisements. Established in 2011, iBrand Africa represents years of business experience and consists of a team of like-minded professionals bonded by the common goal of delivering unmatched services.<a href="http://www.ibrandafrica.com/about-us" target="_blank">LEARN MORE</a>'
  }
];

function Faqs() {
    return (
      <>
      <CenterHero title='Have a question?' overlineText='F.A.Q' desc='Search our FAQ for answers to anything you might ask.'  />
      <Container maxWidth='md' sx={{marginY: 5}}>
        {faqs?.map((item, index) => (
          <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id={`panel1a-header_${item?.id}`}
          >
            <Typography variant='h6'>{sentenceCase(item?.title)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <div dangerouslySetInnerHTML={{ __html: item?.desc }} />
          </AccordionDetails>
        </Accordion>
        ))}
      </Container>
      </>
    );
  }
  
  export default Faqs;