import PropType from 'prop-types';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { styled, useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import APIService from '../../../service';
import HiddenInput from './HiddenInput';
import Iconify from '../../Iconify';

const StyledCard = styled(Card)(({ theme }) => ({
  minHeight: 200,
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  cursor: 'pointer',
  transition: 'all 200ms ease-in',
  '&:hover': {
    transform: 'scale(1.03)',
  },
  '&:active': {
    transform: 'scale(0.9)',
  },
}));

const StyleCardContent = styled(CardContent)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));

function DocumentUploadInput(props) {
  const { icon, name, desc, fieldName, value, id, toast, callback, errorStatus } = props;
  const theme = useTheme();
  const [fileName, setFIleName] = useState('');
  const [progress, setProgress] = useState(0);

  const buttonSx = {
    ...(value && {
      bgcolor: theme.palette.success.main,
      '&:hover': {
        bgcolor: theme.palette.success.light,
      },
    }),
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    setFIleName(file?.name);
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      // setFieldValue(reader.result)
    };

    const formData = new FormData();
    formData.append('image', file);

    const uploaded = APIService.post('/media/local-upload?userType=customer', formData, {
      onUploadProgress: (evt) => {
        setProgress(Math.round((100 * evt.loaded) / evt.total));
      },
    });

    toast.promise(uploaded, {
      loading: 'Uploading...',
      success: (res) => {
        setProgress(0);
        callback(res.data, id);
        return `${fieldName} uploaded successfully!`;
      },
      error: (err) => {
        setProgress(0);
        return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
      },
    });
  };

  return (
    <>
      <HiddenInput
        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
        id={id}
        type="file"
        onChange={handleUpload}
      />
      <label htmlFor={id}>
        <StyledCard>
          <StyleCardContent>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ rotate: 360, scale: 1 }}
              style={{ position: 'relative' }}
              transition={{
                type: 'spring',
                stiffness: 260,
                damping: 20,
              }}
            >
              <Fab aria-label="save" color="secondary" sx={buttonSx}>
                {value ? (
                  <CheckIcon />
                ) : (
                  <Iconify
                    icon={icon}
                    sx={{
                      backgroundColor: theme.palette.secondary.lighter,
                      color: theme.palette.secondary.main,
                      width: 70,
                      height: 70,
                      padding: 1,
                      borderRadius: 100,
                    }}
                  />
                )}
              </Fab>
              {progress ? (
                <CircularProgress
                  size={68}
                  sx={{
                    color: theme.palette.success.main,
                    position: 'absolute',
                    top: -6,
                    left: -6,
                    zIndex: 1,
                  }}
                />
              ) : null}
            </motion.div>
            {value ? (
              <Typography variant="h5" lineHeight={1.2} sx={{ mt: 2 }} gutterBottom>
                {name?.replace('Add', ' ')} is currently on review
              </Typography>
            ) : (
              <div style={{ marginTop: 15 }}>
                <Typography variant="h5" lineHeight={1.2} gutterBottom>
                  {name}
                </Typography>
                <Typography variant="body1" textAlign="center" color="text.secondary">
                  {desc}
                </Typography>
              </div>
            )}
          </StyleCardContent>
        </StyledCard>
      </label>
      {errorStatus.field === id ? (
        <FormHelperText id={id} sx={{ color: theme.palette.error.main, paddingX: 2 }}>
          {errorStatus.message}
        </FormHelperText>
      ) : null}
    </>
  );
}

export default DocumentUploadInput;

DocumentUploadInput.propTypes = {
  icon: PropType.string,
  name: PropType.string,
  desc: PropType.string,
  fieldName: PropType.string,
  value: PropType.string,
  id: PropType.string,
  toast: PropType.func,
  callback: PropType.func,
  errorStatus: PropType.object,
};
