import { useEffect, useState } from 'react';
import { sentenceCase } from 'change-case';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import colorVariant from '../../utils/colorVariant';
import Label from '../Label';
import MoreMenu from '../menu/MoreMenu';
import Empty from '../empty';
import { fToNow } from '../../utils/formatTime';
import formatCurrency from '../../utils/formatCurrency';
import useSWRFetch from '../../hooks/useSWRFetch';
import { PreviewServiceCard } from '../cards';
import CustomModal from '../modal/CustomModal';
import { EditServiceForm } from '../forms';

const drawerWidth = 460;

const StyledDateGrid = styled(DataGrid)(({ theme }) => ({
  width: '100%',
  height: '100% !important',
  //   minHeight: 350,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  '& .MuiDataGrid-columnHeaders': {
    color: theme.palette.text.disabled,
    textTransform: 'uppercase',
    fontWeight: 400,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

// function AdvanceToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }
function ServiceList() {
  const [actionType, setActionType] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const { data, loading, mutate } = useSWRFetch('/service/single');
  const [filteredServices, setFilteredServices] = useState([]);

  useEffect(() => {
    if (data?.length) {
      const mappedData = data?.map((item) => {
        const subPackage =
          item?.package?.category === 'website'
            ? item?.package?.subPackages?.filter((p) => p?._id === item?.subPackage)[0]
            : null;
        return {
          ...item,
          subPackage,
        };
      });
      setFilteredServices(mappedData);
    }
  }, [data]);

  useEffect(() => {
    if (actionType === 'VIEW') {
      setOpenDetails(true);
    }
    if (actionType === 'EDIT') {
      setOpenEditModal(true);
    }
  }, [actionType]);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      flex: 1,
      renderCell: (params) => (
        <StyledTypography variant="body1" noWrap>
          {params?.row?.package?.category === 'website' ? params?.row?.subPackage?.name : params?.row?.package?.name}
        </StyledTypography>
      ),
    },
    {
      field: 'amount',
      sortable: true,
      maxWidth: 200,
      flex: 1,
      renderCell: (params) => <Typography>{formatCurrency(params?.value)}</Typography>,
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      maxWidth: 250,
      flex: 1,
      renderCell: (params) => <StyledTypography variant="body1">{fToNow(params?.value)}</StyledTypography>,
    },
    {
      field: 'status',
      sortable: false,
      maxWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <Label variant="ghost" color={colorVariant(params?.value)}>
          {sentenceCase(params?.value)}
        </Label>
      ),
    },
    {
      field: 'id',
      headerName: '',
      sortable: false,
      width: 50,
      disableColumnMenu: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          setSelected(params.row);
        };
        return <MoreMenu handleClick={onClick} setActionType={setActionType} setConfirm={setConfirm} type="service" />;
      },
    },
  ];

  const closeDetails = () => {
    setActionType('');
    setOpenDetails(false);
  };

  const closeEdit = () => {
    setActionType('');
    setOpenEditModal(false);
  };

  return (
    <>
      <StyledDrawer anchor="right" open={openDetails} onClose={closeDetails}>
        <DrawerHeader>
          <IconButton onClick={closeDetails}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <PreviewServiceCard data={selected} />
      </StyledDrawer>
      {openEditModal && (
        <CustomModal open={openEditModal} setOpen={closeEdit} title="Update Service" modalSize="sm" background>
          <EditServiceForm data={selected} mutate={mutate} closeEdit={closeEdit} />
        </CustomModal>
      )}
      <StyledDateGrid
        rows={filteredServices}
        columns={columns}
        loading={loading}
        paginationMode="server"
        rowCount={data?.length || 20}
        components={{
          // Toolbar: AdvanceToolbar,
          NoRowsOverlay: () => <Empty text="No Service" />,
        }}
        disableColumnSelector={false}
        checkboxSelection={false}
        autoHeight
      />
    </>
  );
}

export default ServiceList;
