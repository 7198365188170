import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import Spacer from '../spacer';
import Iconify from '../Iconify';
import formatCurrency from '../../utils/formatCurrency';
import UploadInput from '../forms/input/UploadInput';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  borderRadius: 10,
}));
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 0,
}));

// Bank Name: FIDELITY BANK
// Account Number: 5600779289
// Account name: iBrand Africa Ltd

const Item = ({ keyName, value, bold, fontSize = 16 }) => (
  <ListItem divider secondaryAction={<Typography sx={{ fontSize, fontWeight: 'bolder' }}>{value}</Typography>}>
    <ListItemText
      primary={
        <Typography color="text.secondary" sx={{ fontSize }}>
          {keyName}
        </Typography>
      }
    />
  </ListItem>
);

function BankTransferCard(props) {
  const { amount, handleDone, toast, paymentProof, errorStatus, handleUploadedFile } = props;

  // console.log(props);
  return (
    <StyledCard>
      <StyledCardContent>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" color="text.secondary" fontWeight="normal">
            Make your transfer of <span style={{ fontWeight: 'bolder' }}>{formatCurrency(amount)}</span> to the
            following account details
          </Typography>
          <List>
            <Item keyName="Bank Name" value={'FIDELITY BANK'} bold />
            <Item keyName="Account Number" value={'5600779289'} bold />
            <Item keyName="Account Name" value={'iBrand Africa Ltd'} bold />
          </List>
          <UploadInput
            fieldName="your proof of payment"
            id="paymentProof"
            value={paymentProof}
            errorStatus={errorStatus}
            callback={handleUploadedFile}
            toast={toast}
          />
        </Box>
        <Button variant="contained" color="secondary" sx={{ mx: 2, mt: 3 }} onClick={handleDone}>
          Done
        </Button>
      </StyledCardContent>
    </StyledCard>
  );
}

export default BankTransferCard;

BankTransferCard.propTypes = {
  amount: PropType.number,
  handleDone: PropType.func,
  toast: PropType.func,
  paymentProof: PropType.string,
  errorStatus: PropType.object,
  handleUploadedFile: PropType.func,
};
