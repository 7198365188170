import PropType from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Iconify from '../../components/Iconify';

SearchInput.propTypes = {
  keyword: PropType.string,
  setKeyword: PropType.func,
};

function SearchInput(props) {
  const { keyword, setKeyword } = props;
  return (
    <TextField
      value={keyword}
      onChange={(e) => setKeyword(e.target.value)}
      fullWidth
      placeholder="Start Typing..."
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        ),
      }}
      sx={{ borderRadius: 1, bgcolor: 'background.paper' }}
    />
  );
}

export default SearchInput;
