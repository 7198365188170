import PropTypes from 'prop-types';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import { RequestCallForm } from "../../../../components/forms";

function RequestACall(props) {
    const {title, body} = props;
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

  return (
    <Box>
        <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} md={6}>
                <Typography
                    variant='h2'
                    gutterBottom
                    sx={{ fontWeight: 700 }}
                    data-aos='fade-up'
                >
                    {title}
                </Typography>
                <Typography
                    variant={'h5'}
                    component={'p'}
                    color={'text.secondary'}
                    data-aos='fade-up'
                >
                    {body}
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <RequestCallForm />
            </Grid>
        </Grid>
    </Box>
  );
}

export default RequestACall;

RequestACall.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
};