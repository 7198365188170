import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
// material
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Iconify from '../Iconify';
// component

// ----------------------------------------------------------------------
MoreMenu.propTypes = {
  type: PropTypes.string,
  handleClick: PropTypes.func,
  setConfirm: PropTypes.func,
  setActionType: PropTypes.func,
};

export default function MoreMenu({ handleClick, setConfirm, setActionType, type }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const menuOptionClick = (evt, option) => {
    handleClick(evt);
    setActionType(option);
    setIsOpen(false);
    if (option === 'DELETE') {
      setConfirm(true);
    }
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={(evt) => menuOptionClick(evt, 'VIEW')} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="carbon:view" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={`View Details`} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {type === 'service' ? (
          <MenuItem onClick={(evt) => menuOptionClick(evt, 'EDIT')} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Iconify icon="eva:edit-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={`Update Service`} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
}
