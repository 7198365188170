import PropType from 'prop-types';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { sentenceCase } from 'change-case';
// material
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
// Date Module
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// Third party
import toast, { Toaster } from 'react-hot-toast';
// Services
import APIService from '../../service';
// component
import Iconify from '../Iconify';

import countries from '../../utils/countries';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const usernameExp = /^[a-z0-9]+$/i;

const sex = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
];

const formSchema = Yup.object().shape({
  username: Yup.string()
    .matches(usernameExp, 'Enter a valid username without any special character')
    .trim()
    .lowercase()
    .min(4)
    .max(20)
    .required('Username is required'),
  firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
  phoneNumber: Yup.string().matches(phoneRegExp, 'Enter a valid phone number').required('Phone number is required'),
  emailAddress: Yup.string().email('Email must be a valid email address').required('Email is required'),
  gender: Yup.string(),
  country: Yup.string(),
  address: Yup.string(),
  dob: Yup.string(),
});

ProfileForm.propTypes = {
  profile: PropType.object,
  mutate: PropType.func,
  matches: PropType.bool,
};

function ProfileForm(props) {
  const { mutate, profile, matches } = props;
  const [loading, setLoading] = useState();
  const [countryCode, setCountryCode] = useState('+234');
  const [isValidUsername, setIsValidUsername] = useState(null);

  const formik = useFormik({
    initialValues: {
      username: profile?.username || '',
      firstName: profile?.firstName || '',
      lastName: profile?.lastName || '',
      emailAddress: profile?.emailAddress || '',
      phoneNumber: profile?.phoneNumber?.replace('+234', '0') || '',
      gender: profile?.gender || 'male',
      dob: profile?.dob ? new Date(profile?.dob) : new Date(),
      address: profile?.address || '',
      country: profile?.country || 'Nigeria',
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      setLoading(true);
      const payload = {
        ...values,
        phoneNumber: `${countryCode}${
          values.phoneNumber.charAt(0) === '0' ? values.phoneNumber.substring(1) : values.phoneNumber
        }`,
      };
      const response = APIService.update('/auth', 'update', {
        ...payload,
      });

      toast.promise(response, {
        loading: 'Updating...',
        success: () => {
          setLoading(false);
          mutate();
          return 'Changes Saved Successfully!';
        },
        error: (err) => {
          setLoading(false);
          return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
        },
      });
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;

  const handleChangeCode = (e) => {
    setCountryCode(e.target.value);
  };

  const handleUsernameChange = async (e) => {
    try {
      setIsValidUsername(null);

      const hasError = Boolean(touched.username && errors.username);

      if (!hasError) {
        const response = await APIService.post('/auth/validate-username', { username: e.target.value });
        if (response.data?.status) {
          setIsValidUsername(true);
        }
      }
    } catch (err) {
      setIsValidUsername(false);
      toast.error(err?.response?.data?.message || err?.message || 'Something went wrong, try again.');
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={4} xs={12}>
        <Typography variant="h4">Personal Information</Typography>
        <Typography variant="body2" color="text.secondary">
          Change your WebOnTheGo information using the form.
        </Typography>
      </Grid>
      <Grid item sm={8} xs={12}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Stack spacing={2} sx={{ marginBottom: 2 }}>
              <TextField
                fullWidth
                id="outlined-required"
                autoComplete="username"
                label="Username"
                {...getFieldProps('username')}
                onBlur={handleUsernameChange}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="mdi:at" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {isValidUsername === false ? (
                        <Iconify icon={'mdi:close-thick'} sx={{ color: 'red' }} />
                      ) : isValidUsername === true ? (
                        <Iconify icon={'mdi:check-bold'} sx={{ color: 'green' }} />
                      ) : null}
                    </InputAdornment>
                  ),
                }}
                onKeyPress={(e) => {
                  const validReg = new RegExp(/^[a-z0-9]+$/i).test(e.key);
                  if (!validReg) {
                    e.preventDefault();
                  }
                }}
              />
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2}>
                <TextField
                  fullWidth
                  label="First name"
                  {...getFieldProps('firstName')}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />

                <TextField
                  fullWidth
                  label="Last name"
                  {...getFieldProps('lastName')}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Stack>
              <TextField
                fullWidth
                autoComplete="email-address"
                type="email"
                label="Email address"
                {...getFieldProps('emailAddress')}
                error={Boolean(touched.emailAddress && errors.emailAddress)}
                helperText={touched.emailAddress && errors.emailAddress}
              />
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <TextField
                  select
                  value={countryCode}
                  onChange={handleChangeCode}
                  sx={{ maxWidth: 80 }}
                  SelectProps={{
                    native: true,
                  }}
                >
                  {countries.map((option, index) => (
                    <option key={index} value={option.code}>
                      {`${option.code} - ${option.name}`}
                    </option>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  autoComplete="phone"
                  type="text"
                  label="Phone Number"
                  {...getFieldProps('phoneNumber')}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />
              </Box>
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="gender" sx={{ bgcolor: 'background.paper' }}>
                    <em>Select your Gender</em>
                  </InputLabel>
                  <NativeSelect
                    input={<OutlinedInput variant="outlined" {...getFieldProps('gender')} id="gender" />}
                    id="gender"
                  >
                    {sex.map((gender) => (
                      <option key={gender.value} value={gender.value}>
                        {gender.label}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Stack>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Date of Birth"
                  inputFormat="MM/dd/yyyy"
                  value={values.dob}
                  onChange={(value) => {
                    setFieldValue('dob', value);
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>

              <FormControl fullWidth>
                <InputLabel htmlFor="country" sx={{ bgcolor: 'background.paper' }}>
                  <em>Select your Country</em>
                </InputLabel>
                <NativeSelect
                  input={<OutlinedInput variant="outlined" {...getFieldProps('country')} id="country" />}
                  id="country"
                >
                  {countries?.map((country) => (
                    <option key={country.name} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>

              <TextField
                fullWidth
                autoComplete="address"
                type="text"
                label="Current Address"
                minRows={2}
                multiline
                {...getFieldProps('address')}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
            </Stack>

            <LoadingButton fullWidth={!matches} size="large" type="submit" variant="contained" loading={loading}>
              Save Changes
            </LoadingButton>
          </Form>
          <Toaster />
        </FormikProvider>
      </Grid>
    </Grid>
  );
}

export default ProfileForm;
