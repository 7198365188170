import PropType from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Iconify from '../Iconify';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  height: '100%',
  backgroundImage: "url('/static/images/card-pattern3.png')",
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  textDecoration: 'none',
  transition: 'all 200ms ease-in',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const StyledContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

AnalysisCard.propTypes = {
  name: PropType.string,
  icon: PropType.string,
  count: PropType.number,
  color: PropType.string,
  route: PropType.string,
};

AnalysisCard.defaultProps = {
  count: 0,
  color: '#FFe890',
};

function AnalysisCard(props) {
  const { name, icon, color, count, route } = props;
  return (
    <StyledCard sx={{ bgcolor: alpha(color, 0.1) }} component={RouterLink} to={route}>
      <StyledContent>
        <Iconify
          icon={icon}
          sx={{
            color: 'white',
            width: 40,
            height: 40,
            backgroundColor: color,
            boxShadow: `3px 4px 9px 0px ${alpha(color, 0.5)}`,
            padding: 1,
            borderRadius: 1.5,
          }}
        />
        <Box sx={{ marginLeft: 2 }}>
          <Typography variant="body1" color="text.secondary">
            {name}
          </Typography>
          <Typography variant="h3">{count}</Typography>
        </Box>
      </StyledContent>
    </StyledCard>
  );
}

export default AnalysisCard;
