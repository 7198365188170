import PropType from 'prop-types';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import toast, { Toaster } from 'react-hot-toast';
// Services
import APIService from '../../service';
import Iconify from '../Iconify';

const formSchema = Yup.object().shape({
  firstName: Yup.string().required('firstName required'),
  lastName: Yup.string().required('lastName required'),
  emailAddress: Yup.string().email().required('emailAddress required'),
  subject: Yup.string().required('Subject required'),
  message: Yup.string().required('Message is required'),
});

function ContactForm(props) {
  const { ticketSubjects } = props;
  const [loading, setLoading] = useState();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      subject: '',
      message: '',
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      setLoading(true);
      const response = APIService.post('/support/create', values);

      toast.promise(response, {
        loading: 'Sending...',
        success: (res) => {
          setLoading(false);
          setFieldValue('message', '.');
          return 'Thank your for contacting us.';
        },
        error: (err) => {
          setLoading(false);
          return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
        },
      });
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ width: '100%', textAlign: 'start' }}>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <InputLabel htmlFor="subject" sx={{ bgcolor: 'background.paper' }}>
              <em>Select your subject</em>
            </InputLabel>
            <NativeSelect
              input={<OutlinedInput variant="outlined" {...getFieldProps('subject')} id="subject" />}
              id="subject"
            >
              {ticketSubjects?.map((subject, index) => (
                <option key={index} value={subject?.title}>
                  {subject?.title}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>
          <TextField
            fullWidth
            autoComplete="email-address"
            type="email"
            label="Email address"
            {...getFieldProps('emailAddress')}
            error={Boolean(touched.emailAddress && errors.emailAddress)}
            helperText={touched.emailAddress && errors.emailAddress}
          />
          <TextField
            fullWidth
            label="Message"
            multiline
            rows={8}
            {...getFieldProps('message')}
            error={Boolean(touched.message && errors.message)}
            helperText={touched.message && errors.message}
          />
        </Stack>
        <LoadingButton
          sx={{ marginTop: 2 }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          endIcon={<Iconify icon="ic:sharp-send" />}
        >
          Submit
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

export default ContactForm;

ContactForm.propTypes = {
  ticketSubjects: PropType.array,
};
