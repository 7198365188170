import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const StyledCard = styled(Card)(() => ({
    height: '100%',
    width: '100%',
    borderRadius: 10,
    backgroundColor: 'transparent'

}));

const StyledCardContent = styled(CardContent)(() => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 10,
    textAlign: 'center'
}));

function ResponseCard({ title, subtitle, sx }) {
    return (
        <StyledCard>
            <StyledCardContent>
                <Typography sx={sx} variant='h3' fontWeight={900}>{title}</Typography>
                <Typography sx={sx} variant='body1' textAlign='center'>{subtitle}</Typography>
            </StyledCardContent>
        </StyledCard>
    );
}

export default ResponseCard;

ResponseCard.propTypes = {
    title: PropType.string,
    subtitle: PropType.string,
    sx: PropType.object
};