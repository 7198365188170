import PropType from 'prop-types';
import { useState } from 'react';
import { Form, FormikProvider } from 'formik';
// material
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
// Third party
import countries from '../../utils/countries';

const businessTypes = [
  'Retailers',
  'Construction Businesses',
  'Home Service Professionals',
  'Financial Professionals',
  'Restaurants, Bars, and Night Clubs',
  'Wholesale Companies',
  'Non Profit (NGO)',
  'Transportation Companies',
  'Real Estate Companies',
  'Hospitality Companies',
  'Manufacturing Businesses',
  'Medical Offices',
  'Franchises',
  'Minority-Owned Businesses',
  'Other',
];

const roles = [
  'Executive (CFO)',
  'Manager',
  'Operations and production',
  'Chief executive officer (CEO)',
  'Chief operating officer (COO)',
  'Chief financial officer or controller (CFO)',
  'Chief marketing officer (CMO)',
  'Chief technology officer (CTO)',
  'President',
  'Vice president',
  'Executive assistants',
  'Marketing manager',
  'Product manager',
  'Project manager',
  'Finance manager',
  'Human resources manager (HR)',
  'Accountant',
  'Sales representative',
  'Customer service representative',
  'Administrative assistant',
];

function ServiceForm(props) {
  const { formik, selectedPackage, agreement, setAgreement, agreementError } = props;
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Stack spacing={1}>
          <Stack direction="row" alignItems="start" spacing={1}>
            <TextField
              fullWidth
              label="Business Name"
              {...getFieldProps('businessName')}
              error={Boolean(touched.businessName && errors.businessName)}
              helperText={touched.businessName && errors.businessName}
            />
            <TextField fullWidth label="RC Number" {...getFieldProps('rcNumber')} helperText="optional" />
          </Stack>
          <TextField
            fullWidth
            label="Tell us few things about your business?"
            {...getFieldProps('businessDesc')}
            multiline
            rows={2}
            helperText="Optional"
          />

          <Box sx={{ py: 1 }}>
            <FormControl fullWidth>
              <InputLabel htmlFor="businessType" sx={{ bgcolor: 'background.paper' }}>
                <em>Select your Business Type</em>
              </InputLabel>
              <NativeSelect
                input={<OutlinedInput variant="outlined" {...getFieldProps('businessType')} id="businessType" />}
                id="businessType"
              >
                {businessTypes?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box>
          <Box sx={{ py: 1 }}>
            <FormControl fullWidth>
              <InputLabel htmlFor="role" sx={{ bgcolor: 'background.paper' }}>
                <em>What's your Role?</em>
              </InputLabel>
              <NativeSelect input={<OutlinedInput variant="outlined" {...getFieldProps('role')} id="role" />} id="role">
                {roles?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box>
          {selectedPackage?.category === 'website' && (
            <TextField
              fullWidth
              label="Already have a domain name?"
              {...getFieldProps('domain')}
              placeholder="eg: companyname.com"
              helperText="optional"
            />
          )}
          <Box sx={{ py: 1 }}>
            <FormControl fullWidth>
              <InputLabel htmlFor="country" sx={{ bgcolor: 'background.paper' }}>
                <em>Select your Country</em>
              </InputLabel>
              <NativeSelect
                input={<OutlinedInput variant="outlined" {...getFieldProps('country')} id="country" />}
                id="country"
              >
                {countries?.map((country) => (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box>
          <TextField fullWidth label="Office Address" {...getFieldProps('address')} helperText="Optional" />
        </Stack>
        <FormControl required error={agreementError} component="fieldset" sx={{ mt: 1 }}>
          <FormControlLabel
            control={<Checkbox value={agreement} onChange={(e) => setAgreement(e.target.checked)} />}
            label={
              <div
                dangerouslySetInnerHTML={{
                  __html: `<p>By checking this box, you are agreeing to our <a href'${process.env.REACT_APP_DOMAIN}/terms-of-service'>terms of service</a>.</p>`,
                }}
              />
            }
          />
          {agreementError && <FormHelperText>Check the box to continue</FormHelperText>}
        </FormControl>
      </Form>
    </FormikProvider>
  );
}

export default ServiceForm;

ServiceForm.propTypes = {
  formik: PropType.object,
  selectedPackage: PropType.object,
  agreement: PropType.bool,
  setAgreement: PropType.func,
  agreementError: PropType.bool,
};
