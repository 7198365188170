import { useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const CustomDialog = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  position: 'fixed',
  bottom: 100,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
  width: '100%',
  [theme.breakpoints.between('xs', 'sm')]: {
    bottom: 50,
  },
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.paper,
  padding: theme.spacing(2.5),
  maxWidth: 450,
  marginRight: theme.spacing(2.5),
  marginLeft: theme.spacing(2.5),
  [theme.breakpoints.between('xs', 'sm')]: {
    maxWidth: 'auto',
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: theme.palette.secondary.lighter,
}));

function CookieModal() {
  const cookie = JSON.parse(localStorage.getItem('cookie'));
  const [open, setOpen] = useState(cookie || false);

  useEffect(() => {
    if (cookie === null) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem('cookie', false);
    setOpen(false);
  };

  return (
    <CustomDialog>
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <CustomPaper elevation={4}>
          <Typography variant="body1" gutterBottom>
            We use cookies to analyze site performance and deliver personalized content. By clicking Dismiss, you
            consent to our{' '}
            <Link
              {...{
                component: RouterLink,
                to: '/cookie-policy',
                color: 'secondary',
                style: { textDecoration: 'none' },
              }}
            >
              Cookie Policy
            </Link>
          </Typography>
          <div style={{ textAlign: 'center' }}>
            <CustomButton onClick={handleClose} color="secondary" size="large">
              Dismiss
            </CustomButton>
          </div>
        </CustomPaper>
      </Slide>
    </CustomDialog>
  );
}

export default CookieModal;
