import PropTypes from 'prop-types';
import {Link as RouterLink } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const AboutBottom = (props) => {
  const {  title, desc, image, link } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={isMd ? 4 : 2}>
      <Grid item container alignItems="center" xs={12} md={6}>
          <Box>
            <Typography
              variant="h4"
              data-aos={'fade-up'}
              gutterBottom
              sx={{
                fontWeight: 700,
              }}
            >
             {title}
            </Typography>
            <Typography data-aos={'fade-up'} gutterBottom>
              {desc}
            </Typography>
            {link ? <Link component={RouterLink}  to='/about' sx={{textDecoration: 'none'}} color='secondary'>Learn More.</Link> : null}
          </Box>
        </Grid>
        <Grid item container justifyContent={'center'} data-aos={'fade-right'} xs={12} md={6}>
          <Box
            component={LazyLoadImage}
            effect="blur"
            src={image}
            width={1}
            height={1}
            maxWidth={400}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutBottom;

AboutBottom.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
};
