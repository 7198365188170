import PropType from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import { sentenceCase } from 'change-case';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Iconify from '../Iconify';
import { fToNow } from '../../utils/formatTime';
import Label from '../Label';
import colorVariant from '../../utils/colorVariant';
import './ticket.css';

const StyledCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    height: '100%',
  },
  backgroundColor: theme.palette.background.paper,
  marginBottom: 10,
}));

const StyleCardContent = styled(CardContent)(({ theme }) => ({
  height: '100%',
}));

TicketCard.propTypes = {
  data: PropType.object,
};

function TicketCard(props) {
  const { data } = props;

  function getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const generatedColor = getRandomColor();

  return (
    <StyledCard>
      <StyleCardContent>
        <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Iconify
              icon="fluent:ticket-diagonal-20-filled"
              sx={{
                width: 35,
                height: 35,
                borderRadius: 80,
                padding: 0.4,
                backgroundColor: alpha(generatedColor, 0.1),
                color: generatedColor,
              }}
            />
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body1" color="text.secondary" fontWeight="bolder">
                <span style={{ fontWeight: 'lighter' }}>Ticket</span> #{data?.ticketId}
              </Typography>
              <Label variant="ghost" color={colorVariant(data?.status)}>
                {sentenceCase(data?.status)}
              </Label>
            </Stack>
          </Stack>
          <Typography variant="body1" color="text.disabled">
            {fToNow(data?.createdAt)}
          </Typography>
        </Stack>
        <Typography variant="h5" fontWeight="bolder" gutterBottom>
          {sentenceCase(data?.message)}
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: data?.solution }} />
      </StyleCardContent>
    </StyledCard>
  );
}

export default TicketCard;
