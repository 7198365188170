import Container from "../../components/container";
import Iconify from "../../components/Iconify";
import AboutBottom from "./components/AboutBottom/AboutBottom";
import { Contact } from "./components/Contact";
import MiniHero from "./components/Hero/MiniHero";
import ThreePoints from "./components/ThreePoints";
import {Promo} from "./components/Promo";
import RequestACall from "./components/RequestCall";


const mock = [
  {
    title: 'Website development',
    subtitle: 'Our team of experts have acquired years of hands-on experience. We will build you a website that expresses your desires and represents your interest.',
    icon: <Iconify icon='bx:code-alt' />,
  },
  {
    title: 'Hosting services',
    subtitle:
      'Our team of experts have acquired years of hands-on experience. We will build you a website that expresses your desires and represents your interest.',
    icon: (
      <Iconify icon='clarity:host-outline-badged' />
    ),
  },
  {
    title: 'Domain name acquisition (if now available)',
    subtitle:
      'If your brand is new to the digital space, we will help you to acquire the domain that best reflects your values.',
    icon: (
      <Iconify icon='gridicons:domains' />
    ),
  },
  {
    title: 'SSL certificate',
    subtitle: 'The security of your site is guaranteed. We will provide you with the technology that authenticates your website\'s identity and enable encrypted connections.',
    icon: (
      <Iconify icon='bi:shield-lock-fill' />
    ),
  },
  // {
  //   title: 'Website monitoring',
  //   subtitle:
  //     'We deploy constant surveillance to ensure that bugs do not infest your site. Our team makes sure everything works optimally.',
  //   icon: (
  //     <Iconify icon='bx:support' />
  //   ),
  // },
  {
    title: 'Expert consultancy and friendly customer service',
    subtitle:
      'For inquiries and support, our dedicated team are always on grounds to offer professional assistance and quick resolution of difficulties.',
    icon: (
      <Iconify icon='bx:support' />
    ),
  },
];

const contactList = [
  {
    title: 'Phone',
    subtitle: '+234-802-060-0000, +234 904 333 3394',
    icon: <Iconify icon='carbon:phone-filled' />,
  },
  {
    title: 'Email',
    subtitle: 'info@webonthego.co',
    icon: <Iconify icon='ic:round-email' />,
  },
  {
    title: 'Address',
    subtitle: 'House 7, 4th Street, Elekahia Housing Estate, Port Harcourt, NIGERIA. Paul Kigunda Plaza, Tom Mboya Street, P. O Box 142-60200, Meru Town Meru County, Eastern Province KENYA',
    icon: <Iconify icon='fa-solid:location-arrow' />,
  },
];


function AboutUs() {
  return (
    <>
    <MiniHero title='About Us' image='/static/images/banner-page1.png' desc='We are a subsidiary of IBrand Africa Ltd.' />
    <Container>
        <AboutBottom
          title='About WebOnTheGo'
          image='/static/images/illustration1.svg'
          desc='Webonthego by iBrand Africa is a revolutionary service delivery which provides the solution to the core challenges faced by corporate entities in the digital world: the WEBSITE. It offers comprehensive website development and management services at almost no cost. With webonthego, you pay a monthly subscription, and our experts will design, develop and publish the ideal website for your brand.
          We will also perform routine maintenance and monitoring to ensure your site is secure and updated. Provided your subscription is active, your website is assured, with no extra charges.'
        />
      </Container>
      <Container>
        <ThreePoints
        title='What to expect'
        desc='Web on the go by iBrand Africa is a revolutionary service delivery which provides the solution to the core challenges faced by corporate entities in the digital world: the WEBSITE. It offers comprehensive website development and management services at almost no cost. With webonthego, you pay a monthly subscription, and our experts will design, develop and publish the ideal website for your brand.
        We will also perform routine maintenance and monitoring to ensure your site is secure and updated. Provided your subscription is active, your website is assured, with no extra charges.'
        image="/static/images/sample.png"
        items={mock}
        />
      </Container>
      <Container>
      <Promo title='Become an Affiliate Partner' body='We designed our affiliate program such that you get a monthly benefit for referring people to use webonthego. Every time your referrals renew their subscription, you get paid. If they continue to renew the subscription indefinitely, you get paid indefinitely. Just help people find this exceptional service and benefit as much as they do. They’ll thank you, and we’ll reward you.' buttonText='Get Started' buttonLink='/signup' />

      </Container>
      <Container>
        <Contact
          title='Contact us'
          desc='Get in touch with us today, we’d love to hear from you.'
          items={contactList}
        image="/static/images/support.png"
        />
      </Container>
      <Container>
        <RequestACall title='Request a call' body='We know that schedules can be inconvenient. Request a call now and our team will call you at the preferred time and date.' />
      </Container>
    </>
  );
}

export default AboutUs;