import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  size: PropTypes.number,
  fontSize: PropTypes.number,
  sx: PropTypes.object,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  dark: PropTypes.bool,
};

Logo.defaultProps = {
  disabledLink: false,
  dark: false,
  size: 40,
  fontSize: 18,
};

export default function Logo({ title, titleColor, dark, size, disabledLink, fontSize, sx }) {
  const logo = (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
      <Box sx={{ width: size, height: size }}>
        <img src={dark ? '/static/images/logo-white.svg' : '/static/images/logo.svg'} width="100%" alt="..." />
      </Box>
      {title && (
        <Typography component="h1" sx={{ color: titleColor, fontSize, marginLeft: 1, fontWeight: 'normal' }}>
          <span style={{ fontWeight: 900 }}>Web</span>OnTheGo
        </Typography>
      )}
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <RouterLink to="/" style={{ textDecoration: 'none', flexGrow: 1 }}>
      {logo}
    </RouterLink>
  );
}
