import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// components
// ----------------------------------------------------------------------

const ContentStyle = styled('div')(() => ({
  margin: 'auto',
  textAlign: 'center',
}));

// ----------------------------------------------------------------------

export default function NotFound() {
  return (
    <ContentStyle>
      {/* <Typography variant="h3" paragraph>
        Sorry, page not found!
      </Typography>

      <Typography sx={{ color: 'text.secondary' }}>
        Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
        spelling.
      </Typography> */}

      <Box component="img" src="/static/images/error_404.svg" sx={{ height: 260, mx: 'auto', my: { xs: 2, sm: 5 } }} />

      <Stack spacing={1}>
        <Button to="/" size="large" variant="text" component={RouterLink}>
          Go back to Login
        </Button>
      </Stack>
    </ContentStyle>
  );
}
