import { useState } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import APIService from '../../../service';
import HiddenInput from './HiddenInput';
import Iconify from '../../Iconify';

function UploadInput(props) {
  const { fieldName, value, id, toast, callback, errorStatus } = props;
  const [fileName, setFIleName] = useState('');
  const [progress, setProgress] = useState(0);

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    setFIleName(file?.name);
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      // setFieldValue(reader.result)
    };

    const formData = new FormData();
    formData.append('image', file);

    const uploaded = APIService.post('/media/local-upload?userType=customer', formData, {
      onUploadProgress: (evt) => {
        setProgress(Math.round((100 * evt.loaded) / evt.total));
      },
    });

    toast.promise(uploaded, {
      loading: 'Uploading...',
      success: (res) => {
        setProgress(0);
        callback(res.data, id);
        return `${fieldName} uploaded successfully!`;
      },
      error: (err) => {
        setProgress(0);
        return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
      },
    });
  };

  return (
    <Box>
      {progress ? <LinearProgress variant="determinate" value={progress} /> : null}
      <HiddenInput
        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
        id={id}
        type="file"
        onChange={handleUpload}
      />
      <label htmlFor={id}>
        <Button
          variant="outlined"
          size="large"
          color={errorStatus.field === id ? 'error' : 'primary'}
          disabled={!!value}
          endIcon={value ? <Iconify icon="eva:checkmark-circle-outline" /> : null}
          fullWidth
          component="span"
        >
          {value ? (
            `${fieldName} Uploaded`
          ) : (
            <div>
              <Box component="span" color={!fileName ? 'primary.main' : 'text.secondary'}>
                {!fileName ? `Upload ${fieldName}` : `${fieldName}: `}
              </Box>
              {fileName}
            </div>
          )}
        </Button>
        {errorStatus.field === id ? (
          <FormHelperText id={id} sx={{ color: '#FF4842', paddingX: 2 }}>
            {errorStatus.message}
          </FormHelperText>
        ) : null}
      </label>
    </Box>
  );
}

export default UploadInput;
