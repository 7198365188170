import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import useSWRFetch from '../../hooks/useSWRFetch';
// components
import Page from '../../components/Page';
import { AffiliateList } from '../../components/list';

import Iconify from '../../components/Iconify';
import CustomModal from '../../components/modal/CustomModal';
import { useState } from 'react';
import { ShareCard } from '../../components/cards';

function Affiliates({ profile }) {
  const referralCode = profile?.username;
  const [openModal, setOpenModal] = useState(false);

  return (
    <Page title="Affiliates" sx={{ height: '100%' }}>
      <CustomModal open={openModal} setOpen={setOpenModal} title="" modalSize="sm" background>
        <ShareCard referralCode={referralCode} />
      </CustomModal>
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">Affiliates</Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setOpenModal(true)}>
            Invite
          </Button>
        </Stack>
        <Box height="100%" marginTop={2}>
          <AffiliateList />
        </Box>
      </Container>
    </Page>
  );
}

export default Affiliates;
