import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MoreMenu from '../menu/MoreMenu';
import Empty from '../empty';
import { fToNow } from '../../utils/formatTime';
import { PreviewTransactionCard } from '../cards';
import formatCurrency from '../../utils/formatCurrency';
import useSWRFetch from '../../hooks/useSWRFetch';
import colorVariant from '../../utils/colorVariant';
import iconVariant from '../../utils/iconVariant';
import Iconify from '../Iconify';

const drawerWidth = 460;

const StyledDateGrid = styled(DataGrid)(({ theme }) => ({
  width: '100%',
  height: '100% !important',
  //   minHeight: 350,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  '& .MuiDataGrid-columnHeaders': {
    color: theme.palette.text.disabled,
    textTransform: 'uppercase',
    fontWeight: 400,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 30,
  height: 30,
  marginRight: 5,
  [theme.breakpoints.down('sm')]: {
    width: 20,
    height: 20,
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

// function AdvanceToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }
function TransactionList() {
  const [actionType, setActionType] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [selected, setSelected] = useState(null);

  const { data, loading } = useSWRFetch('/transaction/single');
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  useEffect(() => {
    if (data?.length) {
      setFilteredTransactions(data);
    }
  }, [data]);

  useEffect(() => {
    if (actionType === 'VIEW') {
      setOpenDetails(true);
    }
  }, [actionType]);

  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          <StyledAvatar sx={{ bgcolor: colorVariant(params?.value) === 'success' ? '#8bc34a' : '#ff5252' }}>
            <Iconify icon={iconVariant(params?.value)} />
          </StyledAvatar>
          <StyledTypography variant="body1">{params?.row?.message}</StyledTypography>
        </Stack>
      ),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      align: 'left',
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => <StyledTypography variant="body1">{formatCurrency(params?.value)}</StyledTypography>,
    },
    {
      field: 'reference',
      headerName: 'Reference',
      maxWidth: 250,
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      maxWidth: 120,
      flex: 1,
      renderCell: (params) => <StyledTypography variant="body1">{fToNow(params?.value)}</StyledTypography>,
    },
    {
      field: 'id',
      headerName: '',
      sortable: false,
      width: 50,
      disableColumnMenu: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          setSelected(params.row);
        };
        return (
          <MoreMenu handleClick={onClick} setActionType={setActionType} setConfirm={setConfirm} type="transaction" />
        );
      },
    },
  ];

  const closeDetails = () => {
    setActionType('');
    setOpenDetails(false);
  };

  const handleMoreAction = (arg) => {};

  return (
    <>
      <StyledDrawer anchor="right" open={openDetails} onClose={closeDetails}>
        <DrawerHeader>
          <IconButton onClick={closeDetails}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <PreviewTransactionCard data={selected} />
      </StyledDrawer>
      <StyledDateGrid
        rows={filteredTransactions}
        columns={columns}
        loading={loading}
        components={{
          // Toolbar: AdvanceToolbar,
          NoRowsOverlay: () => <Empty text="No Transaction" />,
        }}
        paginationMode="server"
        rowCount={data?.length || 20}
        disableColumnSelector={false}
        checkboxSelection={false}
        autoHeight
      />
    </>
  );
}

export default TransactionList;
