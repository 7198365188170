import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

const mock = [
  {
    feedback:
      '“I can sleep easy, knowing that a dedicated team of experts are in charge of my business website. All I have to do is write to them or all and all I have in mind about the website becomes a reality. The best part is how cheap and flexible it is. If this isn’t a blessing, I don’t know what is.',
    name: 'Joel Matthew',
    title: 'Manager @Busyexpand',
    avatar: '/static/images/customer3.jpg',
    link: 'www.busyexpand.com'
  },
  {
    feedback:
      'Making extra cash online has never been easier than with webonthego. Normally, referral systems reward you when you successfully make a referral or for a chain of ‘downlines”’. Webonthego rewards you every time your referrals renew their subscription.',
    name: 'Nnadi Justice',
    title: 'Digital Marketing expert',
    avatar: '/static/images/customer2.jpeg'
  },
  {
    feedback:
      'If you broke the cost of what webonthego offers and tried to pay individually, you’ll quickly get discouraged because it is so much. With webonthego however, you get very low cost, a monthly subscription plan and quality that beats what people charge alarming rates for.',
    name: 'Joyce B.A Njuare',
    title: 'Vice President, Explore Africa Foundation',
    avatar: '/static/images/customer1.jpeg',
    link: 'www.exploreafricafoundation.org'
  },
];

const Reviews = () => (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h3"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
            color: 'common.white',
          }}
        >We have earned the trust of the world’s most innovative minds and businesses – big and small.        </Typography>
        <Typography
          variant="body1"
          align={'center'}
          data-aos={'fade-up'}
          sx={{ color: 'common.white' }}
        >
         Through excellent delivery, we have left a lasting impression on firms and innovators across the globe. They share their experiences using WebOnTheGo, and here is what they have to say.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
              component={Card}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              boxShadow={0}
              variant={'outlined'}
              borderRadius={2}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ paddingBottom: 2 }}>
                  <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                    <ListItemAvatar sx={{ marginRight: 3 }}>
                      <Avatar
                        src={item.avatar}
                        variant={'rounded'}
                        sx={{ width: 100, height: 100, borderRadius: 2 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={item.name}
                      secondary={
                      <div>
                        <Typography variant='body2'>{item.title}</Typography>
                        <Typography varaint='caption' sx={{color: 'primary.main'}}>{item.link}</Typography>
                      </div>
                      }
                      primaryTypographyProps={{fontWeight:'bolder'}}
                    />
                  </ListItem>
                </Box>
                <Typography color="text.secondary">{item.feedback}</Typography>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

export default Reviews;
