import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import ServiceLayout from '../layouts/dashboard/ServiceLayout';
import PublicLayout from '../layouts/public/landing';
import AuthLayout from '../layouts/public/auth';

// import ComingSoonLayout from '../layouts/public/coming_soon';
// @layouts

//@Pages Auth
import { ForgottenPassword, Login, ResetPassword, SignUp } from '../pages/auth';

//@Pages Dashboard
import { Affiliates, Invoices, Overview, Profile, Services, Settings, Support, Transactions } from '../pages/dashboard';
import Checkout from '../pages/dashboard/Checkout';
import NotFound from '../pages/NotFound';
import {
  AboutUs,
  // ComingSoon,
  CookiePolicy,
  Faqs,
  Help,
  Home,
  Package,
  Pricing,
  PrivacyPolicy,
  TermsService,
} from '../pages/public';

// ----------------------------------------------------------------------

export default function Router({ isAuth, profile, loading, mutate }) {
  return useRoutes([
    {
      path: '/dashboard',
      element: isAuth ? <DashboardLayout loading={loading} profile={profile} /> : <Navigate to="/login" />,
      children: [
        { path: '/dashboard', element: <Navigate to="/dashboard/overview" /> },
        { path: 'overview', element: <Overview profile={profile} mutate={mutate} /> },
        {
          path: 'services',
          element: <ServiceLayout />,
          children: [
            {
              path: 'all',
              element: <Services profile={profile} />,
            },
            {
              path: 'checkout',
              element: <Checkout profile={profile} />,
            },
          ],
        },
        { path: 'invoices', element: <Invoices profile={profile} /> },
        { path: 'transactions', element: <Transactions profile={profile} /> },
        { path: 'affiliates', element: <Affiliates profile={profile} /> },
        { path: 'profile', element: <Profile profile={profile} mutate={mutate} /> },
        { path: 'support', element: <Support profile={profile} /> },
        { path: 'settings', element: <Settings /> },
      ],
    },
    {
      path: '/',
      element: !isAuth && !profile ? <PublicLayout loading={loading} /> : <Navigate to="/dashboard" />,
      children: [
        { path: '/', element: <Navigate to="/home" /> },
        { path: 'home', element: <Home /> },
        { path: 'about', element: <AboutUs /> },
        {
          path: 'pricing',
          element: <Pricing />,
        },
        {
          path: 'pricing/:id',
          element: <Package />,
        },
        { path: 'help', element: <Help /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'terms-of-service', element: <TermsService /> },
        { path: 'cookie-policy', element: <CookiePolicy /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: 'account',
      element: <AuthLayout />,
      children: [
        { path: 'login', element: <Login mutate={mutate} /> },
        { path: 'signup', element: <SignUp mutate={mutate} /> },
        { path: 'forgotten-password', element: <ForgottenPassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);

  // return useRoutes([
  //   {
  //     path: '/',
  //     element: <ComingSoonLayout />,
  //     children: [
  //       { path: '/', element: <ComingSoon /> },
  //       { path: '404', element: <NotFound /> },
  //       { path: '*', element: <Navigate to="/404" /> }
  //     ]
  //   },
  //   { path: '*', element: <Navigate to="/404" replace /> },
  // ]);
}
