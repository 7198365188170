import PropType from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';
import LoadingButton from '@mui/lab/LoadingButton';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Iconify from '../Iconify';
import formatCurrency from '../../utils/formatCurrency';
import colorVariant from '../../utils/colorVariant';
import { sentenceCase } from 'change-case';
import Label from '../Label';
import formatDate from '../../utils/formatDate';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  borderRadius: 10,
  transition: 'all 200ms ease-in',
  '&:hover': {
    transform: 'scale(1.01)',
  },
}));

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: 'text.primary',
  textTransform: 'capitalize',
}));

const Item = ({ keyName, value, bold, fontSize = 14 }) => (
  <ListItem
    divider
    secondaryAction={
      <StyledTypography sx={{ fontSize, color: 'text.primary', fontWeight: bold ? 'bolder' : 'normal' }}>
        {value}
      </StyledTypography>
    }
  >
    <ListItemText
      primary={
        <StyledTypography sx={{ fontSize, color: 'text.secondary', fontWeight: bold ? 'bolder' : 'normal' }}>
          {keyName}
        </StyledTypography>
      }
    />
  </ListItem>
);

PreviewTransactionCard.propTypes = {
  data: PropType.object,
};

function PreviewTransactionCard(props) {
  const { data } = props;

  return (
    <StyledCard>
      <StyledCardContent>
        <Box>
          <Item keyName="Amount" value={formatCurrency(data?.amount)} fontSize={18} bold />
          <Item
            keyName="Transaction Status"
            value={
              <Label variant="ghost" color={colorVariant('success')}>
                {`${data?.gateway_response}` || 'Successful'}
              </Label>
            }
          />
          <Item keyName="channel" value={data?.channel} />
          <Item keyName="currency" value={data?.currency} />
          <Item keyName="reference" value={data?.reference} />
          <Item keyName="message" value={data?.message} />
          <Item keyName="Transaction Type" value={data?.type} />
          <Item keyName="Date" value={formatDate(data?.createdAt)} />
        </Box>
      </StyledCardContent>
    </StyledCard>
  );
}

export default PreviewTransactionCard;
