import PropType from 'prop-types';
import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import toast, { Toaster } from 'react-hot-toast';
// Services
import countries from '../../utils/countries';
import APIService from '../../service';
import Iconify from '../Iconify';

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const formSchema = Yup.object().shape({
    name: Yup.string().required('firstName required'),
    emailAddress: Yup.string().email().required('EmailAddress required'),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Enter a valid phone number').required('Phone number is required'),
    type: Yup.string()
});

function WaitListForm({ closeModal }) {
    const [loading, setLoading] = useState();
    const [countryCode, setCountryCode] = useState('+234');

    const formik = useFormik({
        initialValues: {
            name: '',
            emailAddress: '',
            phoneNumber: '',
            type: 'waitlist'
        },
        validationSchema: formSchema,
        onSubmit: async () => {
            setLoading(true);
            const response = APIService.post('/contact/create', {
                ...values,
                phoneNumber: `${countryCode}${values.phoneNumber.charAt(0) === '0' ? values.phoneNumber.substring(1) : values.phoneNumber
                    }`,
            });

            toast.promise(
                response,
                {
                    loading: 'subscribing...',
                    success: () => {
                        setLoading(false);
                        closeModal();
                        return 'Thank you for joining our waitlist.';
                    },
                    error: (err) => {
                        setLoading(false);
                        return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
                    },
                },
                {
                    success: {
                        duration: 10000,
                    },
                }
            );
        },
    });

    const handleChangeCode = (e) => {
        setCountryCode(e.target.value);
    };

    const { errors, touched, values, handleSubmit, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ width: '100%', textAlign: 'start' }}>
                <Stack spacing={2}>
                    <TextField
                        fullWidth
                        autoComplete="name"
                        label="Fullname"
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                    />
                    <TextField
                        fullWidth
                        autoComplete="email-address"
                        type="email"
                        label="Email address"
                        {...getFieldProps('emailAddress')}
                        error={Boolean(touched.emailAddress && errors.emailAddress)}
                        helperText={touched.emailAddress && errors.emailAddress}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <TextField
                            select
                            value={countryCode}
                            onChange={handleChangeCode}
                            sx={{ maxWidth: 80 }}
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {countries.map((option, index) => (
                                <option key={index} value={option.code}>
                                    {`${option.code} - ${option.name}`}
                                </option>
                            ))}
                        </TextField>
                        <TextField
                            fullWidth
                            label="Phone Number"
                            {...getFieldProps('phoneNumber')}
                            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                            helperText={touched.phoneNumber && errors.phoneNumber}
                        />
                    </Box>
                    <LoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={loading}
                        startIcon={<Iconify icon="ic:outline-unsubscribe" />}
                    >
                        Subscribe Now
                    </LoadingButton>
                </Stack>
            </Form>
            <Toaster gutter={30} />
        </FormikProvider>
    );
}

export default WaitListForm;
