import Carousel from 'react-material-ui-carousel';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const items = [
  {
    title: 'Build. Deploy. Scale.',
    image: '/static/images/banner1.jpg',
  },
  {
    title: 'Build Your Unique Online Presence',
    image: '/static/images/banner2.jpg',
  },
];

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.primary.main,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: 0,
}));

const Content = styled('div')({
  position: 'absolute',
  bottom: 0,
  margin: 40,
});

function Item(props) {
  return (
    <StyledPaper
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.53)),
    url('${props.image}')`,
      }}
    >
      <Content>
        <Typography variant="h1" sx={{ fontWeight: 'bolder', color: 'white' }}>
          {props.title}
        </Typography>
      </Content>
    </StyledPaper>
  );
}

function AuthCarousel() {
  return (
    <Carousel autoPlay={true} indicators={false} interval={8000} height="100vh">
      {items.map((item, i) => (
        <Item key={i} {...item} />
      ))}
    </Carousel>
  );
}

export default AuthCarousel;
